import {AppAspectDefinition} from "../../javascript.lib.mojo-base/model/app.aspect/AppAspectDefinition";
import { AppQuestion } from "../../javascript.lib.mojo-base/model/AppQuestion";
import {FacilityEnumeratedConstants} from "./FacilityEnumeratedConstants";
import {EnumeratedConstantReference} from "../../javascript.lib.mojo-base/model/EnumeratedConstant";



export class DiageoQuestions {


  static readonly aspects: AppAspectDefinition[] = [
    new AppAspectDefinition({
      name: 'Parking and Entrance',
      // firebaseAnswersRoot: '/answers-x/facilities',
      firebaseAspectId: 'parking-and-entrance',
      questions: [


        //
        //
        // PARKING (row: 3)
        //
        //

        {
          key: 'Bkp11',
          label: 'Is there parking available for visitors/employees?',
          helpImage: null,
          helpText: 'Any area provided by the company for staff or visitors to park cars or other vehicles.',
          popupLabel: 'Onsite Parking',
          type: AppQuestion.TYPE_ENUM,
          type2: {
            typeEnum: {
              options: [
                FacilityEnumeratedConstants.CAR_PARK_FACILITIES.no,
                FacilityEnumeratedConstants.CAR_PARK_FACILITIES.indoor,
                FacilityEnumeratedConstants.CAR_PARK_FACILITIES.outdoor,
                FacilityEnumeratedConstants.CAR_PARK_FACILITIES.both,
              ],
              scoring: [
                // zero for everybody

                // {
                //   enumeration: FacilityEnumeratedConstants.CAR_PARK_FACILITIES.no,
                //   score: 0
                // },
                // {
                //   enumeration: FacilityEnumeratedConstants.CAR_PARK_FACILITIES.indoor,
                //   score: 0
                // },
                // {
                //   enumeration: FacilityEnumeratedConstants.CAR_PARK_FACILITIES.outdoor,
                //   score: 0
                // },
                // {
                //   enumeration: FacilityEnumeratedConstants.CAR_PARK_FACILITIES.both,
                //   score: 0
                // },
              ],
            }
          },
          maximumScore: 0,

          spreadsheetRow: 3,
          enumOptions: [
            FacilityEnumeratedConstants.CAR_PARK_FACILITIES.no,
            FacilityEnumeratedConstants.CAR_PARK_FACILITIES.indoor,
            FacilityEnumeratedConstants.CAR_PARK_FACILITIES.outdoor,
            FacilityEnumeratedConstants.CAR_PARK_FACILITIES.both,
          ]
        },
        {
          key: 'MpjwN',
          label: 'Are parking charges in place?',
          helpImage: null,
          helpImages: [],
          helpText: 'If car parking is provided, is there a charge to park a vehicle on the grounds of your premises?',
          popupLabel: '',
          type: AppQuestion.TYPE_BOOLEAN,
          maximumScore: 0,

          spreadsheetRow: null,
          dependant: {
            questionKey: 'Bkp11',
            truthyEnumCodes: [
              FacilityEnumeratedConstants.CAR_PARK_FACILITIES.indoor.codeAsNumber,
              FacilityEnumeratedConstants.CAR_PARK_FACILITIES.both.codeAsNumber,
              FacilityEnumeratedConstants.CAR_PARK_FACILITIES.outdoor.codeAsNumber,
            ]
          },
        },
        {
          key: 'Mpj2n',
          label: 'Are any parking payment machines set at a reachable height for wheelchair users and persons of shorter stature?',
          helpImage: null,
          helpImages: [],
          helpText: 'Parking pay stations controls shuld be at a lower height to enable a wheelchair user or someone of short stature reach the ticket dispenser, cash or card slots, and intercom system',
          popupLabel: 'Accessible Payment Machines',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 1,
                onFalse: 0,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: null,
          dependant: {
            questionKey: 'MpjwN',
          },
        },
        {
          key: 'BkqAn',
          label: 'Are there designated wheelchair accessible parking spaces available? \n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/2.BkqAn.jpg'],
          helpText: 'Are there any large parking spaces designated for people with limited mobility and wheelchair users? These spaces should have sufficient space around them for easy access in and out of the vehicle.',
          popupLabel: '',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 4,
        },
        {
          key: 'BkqAx',
          label: 'How many wheelchair parking spaces are provided?  \n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/1.BkqAx.jpg'],
          helpText: 'Ideally, a minimum of 1 in 25 spaces should be designated for drivers and passengers with disabilities.',
          popupLabel: 'Accessible Parking Spaces',
          type: AppQuestion.TYPE_INTEGER,
          type2: {
            typeInteger: {
              minValue: 1,
              maxValue: Number.MAX_VALUE,
              scoring: [
                {
                  greaterThanOrEqualTo: 1,
                  lessThan: 2,
                  score: 1,
                },
                {
                  greaterThanOrEqualTo: 2,
                  lessThan: 3,
                  score: 2,
                },
                {
                  greaterThanOrEqualTo: 3,
                  score: 3,
                },
              ]
            }
          },
          maximumScore: 3,

          spreadsheetRow: 5,
          dependant: {
            questionKey: 'BkqAn',
          },
        },
        {
          key: 'BkqA7',
          label: 'Is there an accessible entrance leading from the indoor car park to the main building?  ',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/5.BkqA7.jpg'],
          helpText: 'Is there a way to gain access into the building leading from the indoor carpark without the need to climb steps? This could be Level Access, Ramp Access or via an elevator. ',
          popupLabel: 'Accessible entrance from car park',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 6,
          dependant: {
            questionKey: 'Bkp11',
            truthyEnumCodes: [
              FacilityEnumeratedConstants.CAR_PARK_FACILITIES.indoor.codeAsNumber,
              FacilityEnumeratedConstants.CAR_PARK_FACILITIES.both.codeAsNumber,
            ]
          },
        },
        {
          key: 'BkqBF',
          label: 'From the car park, does the building entrance have an intercom or two-way communication system?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/6.BkqBF.jpg'],
          helpText: 'Intercoms or two-way communication system at the door entry system should support voice and a visual text display so that people with hearing difficulties can read instructions or advice on entry. ',
          popupLabel: 'Entrance intercom system',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 0,
                onFalse: 0,
              }
            }
          },
          maximumScore: 0,

          spreadsheetRow: 7,
          dependant: {
            questionKey: 'Bkp11',
            truthyEnumCodes: [
              FacilityEnumeratedConstants.CAR_PARK_FACILITIES.indoor.codeAsNumber,
              FacilityEnumeratedConstants.CAR_PARK_FACILITIES.both.codeAsNumber,
            ]
          },
        },
        {
          key: 'BkqBP',
          label: 'From the car park, is the entrance to the building through two doors in a series?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/7.BkqBP.jpg'],
          helpText: 'Two doors in a series is through two consecutive doors following one after the other in series, sometimes referred to as \'lobby or vestibule\' and includes a space between an exterior opening and an interior opening.',
          popupLabel: 'Two door entry system',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 0,
                onFalse: 0,
              }
            }
          },
          maximumScore: 0,

          spreadsheetRow: 8,
          dependant: {
            questionKey: 'Bkp11',
            truthyEnumCodes: [
              FacilityEnumeratedConstants.CAR_PARK_FACILITIES.indoor.codeAsNumber,
              FacilityEnumeratedConstants.CAR_PARK_FACILITIES.both.codeAsNumber,
            ]
          },
        },
        {
          key: 'BkqBZ',
          label: 'What is the distance between the two doors in the series? (ie. the passage between the two doors). (In cm)\n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/8.BkqBZ.jpg'],
          helpText: 'In centimetres, please measure the distance between the two doors in series, eg. vestibule (lobby). The recommemded distance between the doors is 157cms. The minimum requirement is at least 120cm plus the width of the doors when swinging into the space.',
          popupLabel: 'Distance between two doors entry system',
          type: AppQuestion.TYPE_CM_MEASUREMENT,
          type2: {
            typeCmMeasurement: {
              minValue: 60,
              maxValue: 2 * 1000,
              scoring: [
                {
                  greaterThanOrEqualTo: 158,
                  score: 3
                },
              ]
            }
          },
          maximumScore: 3,

          spreadsheetRow: 9,
          dependant: {
            questionKey: 'BkqBP',
          },
        },
        {
          key: 'BkqBj',
          label: 'Please take 2 photos of the route from the accessible car parking spaces to the entrance of the building - (in landscape mode)',
          helpImage: null,
          helpText: 'A good visual view of the entrance from two different angles. Two photos of the route a visitor would take to get from the car park to the main entrance of the building. ',
          popupLabel: '',
          type: AppQuestion.TYPE_PHOTO,
          maximumScore: 0,

          spreadsheetRow: 10,
          dependant: {
            questionKey: 'BkqAn',
          },
        },


        //
        //
        // Building Entrance (row: 11)
        //
        //

        {
          key: 'BkqBt',
          label: 'Is the alternative accessible entrance clearly sign posted?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/17.BkqBt.png'],
          helpText: 'Are there prominent signs directing people to the accessible entrance into the building? (Ref: 2010 ADA Standards for Accessible Design - 206.6). \n',
          popupLabel: 'Alternative accessible entrance signposted',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 1,
                onFalse: 0,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: 12,
          dependant: {
            questionKey: 'BkqCV'
          },
        },
        {
          key: 'Cjk03',
          label: 'Please take 2 photos of the alternative accessible entrance (in landscape mode)',
          helpImage: null,
          helpText: '',
          popupLabel: '',
          type: AppQuestion.TYPE_PHOTO,
          maximumScore: 0,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'BkqCV'
          },
        },
        {
          key: 'QZ_dz',
          label: 'Do you have any other comments or information about this area?',
          helpImage: null,
          helpText: '',
          popupLabel: '',
          type: AppQuestion.TYPE_TEXT,
          maximumScore: 0,

          spreadsheetRow: -1,
        },
        {
          key: 'BkqB3',
          label: 'Is there step free access into the main building entrance? This could be level, ramp or lift access.',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/13.BkqB3.png'],
          helpText: 'Is there a way to gain access into the building without the need to climb steps? This could be Level Access, Ramp Access or via an External Platform Lift.    \n',
          popupLabel: 'Step free entrance',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 3,
                onFalse: 0,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: 13,
        },
        {
          key: 'BkqCB',
          label: 'How many steps are there up to the main entrance?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/Steps.BkqCB.png'],
          helpText: 'If you have only steps into the main entrance, how many steps do you need to take to reach the entrance door to your building?',
          popupLabel: 'Number of steps into building',
          type: AppQuestion.TYPE_INTEGER,
          type2: {
            typeInteger: {
              minValue: 0,
              maxValue: Number.MAX_VALUE,
              scoring: []
            }
          },
          maximumScore: 0,

          spreadsheetRow: 14,
        },
        {
          key: 'BkqCL',
          label: 'What type of door is there into the main entrance?   ',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/Type-of-Door.BkqCL.png'],
          helpText: 'Is the entrance door automatic, revolving or do you need to manually open the door? Choose most applicable one.\n',
          popupLabel: '[Automatic, Manual, Revolving] door at entrance',
          type: AppQuestion.TYPE_ENUM,
          type2: {
            typeEnum: {
              options: [
                FacilityEnumeratedConstants.DOOR_TYPE.automatic,
                FacilityEnumeratedConstants.DOOR_TYPE.pushPad,
                FacilityEnumeratedConstants.DOOR_TYPE.manual,
                FacilityEnumeratedConstants.DOOR_TYPE.revolving,
              ],
              scoring: [
                {

                  codeAsNumber: FacilityEnumeratedConstants.DOOR_TYPE.automatic.codeAsNumber,
                  score: 3,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.DOOR_TYPE.pushPad.codeAsNumber,
                  score: 2,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.DOOR_TYPE.manual.codeAsNumber,
                  score: 1,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.DOOR_TYPE.revolving.codeAsNumber,
                  score: 0,
                },
              ]
            }
          },
          maximumScore: 3,

          spreadsheetRow: 15,
          enumOptions: [
            FacilityEnumeratedConstants.DOOR_TYPE.automatic,
            FacilityEnumeratedConstants.DOOR_TYPE.manual,
            FacilityEnumeratedConstants.DOOR_TYPE.revolving,
          ]
        },
        {
          key: 'BkqCV',
          label: 'Is there an alternative accessible entrance?  \n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/15.BkqCV.png'],
          helpText: 'Is there an alternative entrance to your building via car park, ramp or lift that a wheelchair user can gain access?\n',
          popupLabel: 'Alternative accessible entrance',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 0,
                onFalse: 0,
              }
            }
          },
          maximumScore: 0,

          spreadsheetRow: 16,
        },
        {
          key: 'CjAUx',
          label: 'Is there step free access into the alternative accessible entrance? This could be levels, access or lift access.',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/17.CjAUx.png'],
          helpText: '',
          popupLabel: 'Step free accessible entrance',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 3,
                onFalse: 0,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'BkqCV'
          },
        },
        {
          key: 'CjEZ3', // What type of door do you have into the alternative accessible entrance?
          label: 'What type of door do you have into the alternative accessible entrance?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/12.CjEZ3.png'],
          helpText: 'Is the entrance door automatic, revolving or do you need to manually open the door? Choose most applicable one.',
          popupLabel: '[Automatic, Manual, Revolving] door at alternative accessible entrance',
          type: AppQuestion.TYPE_ENUM,
          type2: {
            typeEnum: {
              options: [
                FacilityEnumeratedConstants.DOOR_TYPE.automatic,
                FacilityEnumeratedConstants.DOOR_TYPE.manual,
                FacilityEnumeratedConstants.DOOR_TYPE.revolving,
              ],
              scoring: [
                {
                  codeAsNumber: FacilityEnumeratedConstants.DOOR_TYPE.automatic.codeAsNumber,
                  score: 2,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.DOOR_TYPE.manual.codeAsNumber,
                  score: 1,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.DOOR_TYPE.revolving.codeAsNumber,
                  score: 0,
                },
              ]
            }
          },
          maximumScore: 2,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'BkqCV'
          },
        },
        {
          key: 'CjFQt',
          label: 'What is the alternative accessible entrance door width measurement? (In cm)',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/14.CjFQt.jpg'],
          helpText: 'In centimetre’s, please measure the width of the alternative accessible entrance door to your building. (Ref: 2010 ADA Standards-404.2.3 Clear Width).',
          popupLabel: 'Alternative accessible entrance door width',
          type: AppQuestion.TYPE_CM_MEASUREMENT,
          type2: {
            typeCmMeasurement: {
              minValue: 60,
              maxValue: 2 * 1000,
              scoring: [
                {
                  greaterThanOrEqualTo: 85,
                  lessThan: 91,
                  score: 2
                },
                {
                  greaterThanOrEqualTo: 91,
                  score: 3
                },
              ]
            }
          },
          maximumScore: 3,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'BkqCV'
          },
        },
        {
          key: 'BkqCf',
          label: 'What is the main entrance door width measurement? (In cm)',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/13.BkqCf.jpg'],
          helpText: 'In centimetre’s, please measure the width of the main entrance door to your building. If the doorway is made up of two doors, the open door should have a minimum clear width of 85cm.',
          popupLabel: 'Main entrance door width measurement',
          type: AppQuestion.TYPE_CM_MEASUREMENT,
          type2: {
            typeCmMeasurement: {
              minValue: 60,
              maxValue: 2 * 1000,
              scoring: [
                {
                  greaterThanOrEqualTo: 85,
                  lessThan: 91,
                  score: 2
                },
                {
                  greaterThanOrEqualTo: 91,
                  score: 3
                },
              ]
            }
          },
          maximumScore: 3,

          spreadsheetRow: 17,
          dependant: {
            questionKey: 'BkqCL',
            falsyEnumCodes: [
              FacilityEnumeratedConstants.DOOR_TYPE.revolving.codeAsNumber,
            ]
          }
        },
        {
          key: 'BkqCp',
          label: 'Is the main entrance door made of full clear glass panels? ',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/13.BkqCp.png'],
          helpText: 'Are the main entrance doors made of large areas of clear glass or fully glazed doors?',
          popupLabel: 'Glass entrance door',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 0,
                onFalse: 0,
              }
            }
          },
          maximumScore: 0,

          spreadsheetRow: 18,
        },
        {
          key: 'BkqCz',
          label: 'Are safety stickers or decals provided on the glass doors to avoid walking into the glass?  ',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/13.BkqCz.png'],
          helpText: 'Clear glass can be particularly difficult for people with low vision who may not realize there is a barrier in the way. Using decals with dots or stripes to create a visual mark can help distinguish the entrance.',
          popupLabel: 'Visual markings on glass entrance doors',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 19,
          dependant: {
            questionKey: 'BkqCp',
          }
        },
        {
          key: 'BkqC9',
          label: 'Please take 2 photos of the main entrance (in landscape mode)',
          helpImage: null,
          helpText: 'A good visual view of the outside entrance from two different angles.',
          popupLabel: '',
          type: AppQuestion.TYPE_PHOTO,
          maximumScore: 0,

          spreadsheetRow: 20,
        },

        //
        //
        // Reception area (row: 21)
        //
        //


        {
          key: 'Bkve7',
          label: 'Is there a lower counter/desk in the reception area?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/18.Bkve7.png'],
          helpText: 'Is any part of the reception desk at a lower level for children, wheelchair users and people of short stature?  N/A means there is no reception area. ',
          popupLabel: 'Lower counter at reception',
          type: AppQuestion.TYPE_TERNARY,
          type2: {
            typeTernary: {
              scoring: {
                onNotApplicable: 1,
                onTrue: 1,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: 22,
        },
        {
          key: 'BkvfF',
          label: 'Is there an assistive listening system at the reception area? (eg. induction loop)',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/19.BkvfF.png'],
          helpText: 'Assistive Listening or Hearing Enhancement Systems help amplify audible communication. There are hard-wired systems and three types of wireless systems: Induction loop, Infrared, and FM radio transmission.  These can be used by people who have a hearing impairment. ',
          popupLabel: 'Assistive listening system at reception',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 23,
          dependant: {
            questionKey: 'Bkve7',
            truthyTernaryCodes: [
              EnumeratedConstantReference.no.codeAsNumber,
              EnumeratedConstantReference.yes.codeAsNumber,
            ],
          },
        },
        {
          key: 'C9KCH',
          label: 'Is there visitor seating provided at the reception area?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/20.C9KCH.png'],
          helpText: 'Are there any seats (eg. Chairs, couches, benches) available for people to rest from standing?',
          popupLabel: 'Seating at reception',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 1,
                onFalse: 0,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'Bkve7',
            truthyTernaryCodes: [
              EnumeratedConstantReference.no.codeAsNumber,
              EnumeratedConstantReference.yes.codeAsNumber,
            ],
          },
        },
        {
          key: 'BkvfP',
          label: 'Is there directional and informational signage in the reception area? \n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/21.BkvfP.png'],
          helpText: 'Directional and informational signage should be provided within the reception area and uses easily recognised symbols and directional arrows to highlight key facilities such as lifts, stairs, toilets, telephones, and the main building functions. ',
          popupLabel: 'Directional signage in reception',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 1,
                onFalse: 0,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: 24,
        },
        {
          key: 'Mpj2x',
          label: 'Are tactile maps available in the reception/lobby area?',
          helpImage: null,
          helpImages: [],
          helpText: 'Tactile maps or floor plans are designed to be read by touch for people with vision impairments.',
          popupLabel: 'Tactile map in reception',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: null,
          dependant: {
            questionKey: 'Bkve7',
            truthyTernaryCodes: [
              EnumeratedConstantReference.no.codeAsNumber,
              EnumeratedConstantReference.yes.codeAsNumber,
            ],
          },
        },
        {
          key: 'BkvfZ',
          label: 'Please take 2 photos of the reception area  1. Showing reception desk and 2. Directional signage if applicable (in landscape mode)',
          helpImage: null,
          helpText: '',
          popupLabel: '',
          type: AppQuestion.TYPE_PHOTO,
          maximumScore: 0,

          spreadsheetRow: 25,
        },
        {
          key: 'Bkvfj',
          label: 'Are guide dogs or service dogs welcome in the premises?\n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/23.Bkvfj.png'],
          helpText: 'Guide dogs are assistance dogs trained to lead blind and visually impaired people around obstacles.\n',
          popupLabel: 'Guide dogs/service dogs welcome',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 26,
        },

      ],
      sections: {
        evaluation: [
          // #1
          {
            title: 'Parking',
            questionKeys: [
              'Bkp11',
              'MpjwN',
              'Mpj2n',
              'BkqAn',
              'BkqAx',
            ],
            subSections: [],
          },
          // #2
          {
            title: 'Parking',
            questionKeys: [
              'BkqA7',
              'BkqBF',
              'BkqBP',
              'BkqBZ',
            ],
            dependantDescriptor: {
              questionKey: 'Bkp11',
              truthyEnumCodes: [
                FacilityEnumeratedConstants.CAR_PARK_FACILITIES.indoor.codeAsNumber,
                FacilityEnumeratedConstants.CAR_PARK_FACILITIES.both.codeAsNumber,
              ]
            },
            subSections: [],
          },
          // #3
          {
            title: 'Parking',
            questionKeys: [
              'BkqBj',
            ],
            dependantDescriptor: {
              questionKey: 'BkqAn'
            },
            subSections: [],
          },
          // #4
          {
            title: 'Entrance',
            questionKeys: [
              'BkqB3',
              'BkqCB',
              'BkqCL',
              'BkqCf',
              'BkqCp',
              'BkqCz',
            ],
            subSections: [],
          },
          // #5
          {
            title: 'Entrance',
            questionKeys: [
              'BkqC9',
            ],
            subSections: [],
          },
          // #6
          {
            title: 'Entrance',
            questionKeys: [
              'BkqCV',
              'CjAUx',
              'CjEZ3',
              'CjFQt',
              'BkqBt',
            ],
            subSections: [],
          },
          // #7
          {
            title: 'Entrance',
            questionKeys: [
              'Cjk03',
            ],
            dependantDescriptor: {
              questionKey: 'BkqCV'
            },
            subSections: [],
          },
          // #8
          {
            title: 'Reception',
            questionKeys: [
              'Bkve7',
              'BkvfF',
              'C9KCH',
              'BkvfP',
              'Mpj2x',
              'Bkvfj',
            ],
            subSections: [],
          },
          // #9
          {
            title: `Reception`,
            questionKeys: [
              'BkvfZ'
            ],
            subSections: [],
          },
          // #10
          {
            title: `Any other comments or feedback`,
            questionKeys: [
              'QZ_dz' // Do you have any other comments or information about this area?
            ],
            subSections: [],
          },

        ],
        portal: [
        ],
      },
      portal: {
        navigationMenu: {
          iconImgBase: 'assets/service.navigation-menu/fontawesome.building-regular'
        }
      },
      spreadsheetRow: 3,
      uuid: 'ea457b35-7a40-433c-91ff-9e508fc4a6f4'
    }),
    new AppAspectDefinition({
      name: 'Facilities & Event Spaces',
      // firebaseAnswersRoot: '/answers-x/facilities',
      firebaseAspectId: 'facilities-and-event-spaces',
      questions: [

        //
        //
        // Facilities (row: 49)
        //
        //

        {
          key: 'BkyVv',
          label: 'Are baby changing facilities available on the premises? \n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/38.BkyVv.svg'],
          helpText: 'Are baby changing facilities available in your building?\n',
          popupLabel: 'Baby changing facilities',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 42,
        },
        {
          key: 'BkyV5',
          label: 'Is there a baby feeding room on the premises?\n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/39.BkyV5.svg'],
          helpText: 'Is there a quiet room where nursing mothers can breastfeed their babies or express their milk?',
          popupLabel: 'Baby feeding room',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 1,
                onFalse: 0,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: 43,
        },
        {
          key: 'BkyWD',
          label: 'Are there cloakrooms available on the premises?\n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/40.BkyWD.svg'],
          helpText: 'Is there a cloakroom for people to hang their coats or other outerwear when they enter the building?\n',
          popupLabel: 'Cloakrooms available',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 0,
                onFalse: 0,
              }
            }
          },
          maximumScore: 0,

          spreadsheetRow: 44,
        },
        {
          key: 'BkyWN',
          label: 'Is there a cloakroom accessible for a wheelchair user? \n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/41.BkyWN.svg'],
          helpText: 'Accessible cloakrooms include wide entrance door, coat hooks set at different heights, full length mirror. ',
          popupLabel: 'Accessible cloakroom',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 45,
          dependant: {
            questionKey: 'BkyWD',
          }
        },
        {
          key: 'BkyWX',
          label: 'Are there storage lockers available on the premises? \n',
          helpImage: null,
          helpText: 'Are there storage lockers or cabinets units for staff or guests to stow items?\n',
          popupLabel: 'Storage lockers available',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 0,
                onFalse: 0,
              }
            }
          },
          maximumScore: 0,

          spreadsheetRow: 46,
        },
        {
          key: 'BkyWh',
          label: 'Are there storage lockers available at varying heights? \n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/41.BkyWh.svg'],
          helpText: 'Can some storage lockers be reached from a seated position?\n',
          popupLabel: 'Accessible storage lockers',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 1,
                onFalse: 0,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: 47,
          dependant: {
            questionKey: 'BkyWX',
          }
        },
        {
          key: 'BkyWr',
          label: 'Are there gym facilities on the premises?\n',
          helpImage: null,
          helpText: 'Is there is a place that houses gym and exercise equipment for staff to use?',
          popupLabel: 'Gym',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 0,
                onFalse: 0,
              }
            }
          },
          maximumScore: 0,

          spreadsheetRow: 48,
        },
        {
          key: 'BkyW1',
          label: 'Is there step free access into the gym? This could be level, ramp or lift access.',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/42.BkyW1.svg'],
          helpText: 'Step free access could be level access, ramp access or lift access. \n',
          popupLabel: 'Step free gym entrance',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 49,
          dependant: {
            questionKey: 'BkyWr'
          }
        },

        //
        //
        // Hallways & Corridors - Internal route of travel (row: 50)
        //
        //


        {
          key: 'Bky5D',
          label: 'Are main routes clearly defined to assist in orientation for people with visual impairment? For example, by using colour contrast between wall and floor surfaces.',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/44.Bky5D.svg'],
          helpText: 'Main internal routes and corridors should be clearly defined, for example, by choosing appropriate floor, wall and ceiling materials that contrast visually with adjacent surfaces and by using a change in texture of floor coverings. This will benefit people who are blind or partially sighted.',
          popupLabel: 'Colour contrasting wall and floor surfaces',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 51,
        },
        {
          key: 'Bky5N',
          label: 'Is there suitable and sufficient lighting providing good illumination throughout the building?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/45.Bky5N.svg'],
          helpText: 'Lighting in buildings and corridors should be even, diffused and without glare, reflections or shadows. The right lighting can help everyone, especially people with reduced vision move around the building safely and easily. ',
          popupLabel: 'Good lighting throughout the building',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 52,
        },
        {
          key: 'Bky5X',
          label: 'Please take 1 photo of a typical main route/corridor (in landscape mode)',
          helpImage: null,
          helpText: 'Example of good visual contrast and width of corridor',
          popupLabel: '',
          type: AppQuestion.TYPE_PHOTO,
          maximumScore: 0,

          spreadsheetRow: 53,
        },

        //
        //
        // Food Services (row: 54)
        //
        //

        {
          key: 'BkzJT',
          label: 'Is there a cafeteria on the premises?\n',
          helpImage: null,
          helpText: 'Is there a food service location for eating that has table and chairs?\n',
          popupLabel: 'Cafeteria onsite',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 0,
                onFalse: 0,
              }
            }
          },
          maximumScore: 0,

          spreadsheetRow: 55,
        },
        {
          key: 'BkzJd',
          label: 'Does the cafeteria have step free access? This could be level, ramp or lift access.',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/47.BkzJd.svg'],
          helpText: 'Can the food and drinks area be accessed without steps?\n',
          popupLabel: 'Step free access to cafeteria ',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 3,
                onFalse: 0,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: 56,
          dependant: {
            questionKey: 'BkzJT' // Is there a cafeteria on the premises?
          }
        },
        {
          key: 'Mpj27',
          label: 'What type of cafeteria service is provided?',
          helpImage: null,
          helpImages: [],
          helpText: 'Self service allows customers to gather their own food products and pay at a counter. Buffet service allows customers to choose from a selection of food options and be served along a tray slide counter. Counter service allows customers to order food and drink at a counter bar. Table service allows customers place a food order and to be waited on at their table.',
          popupLabel: 'Cafeteria Service',
          type: AppQuestion.TYPE_ENUM,
          type2: {
            typeEnum: {
              options: [
                FacilityEnumeratedConstants.CAFETERIA_TYPE.selfService,
                FacilityEnumeratedConstants.CAFETERIA_TYPE.buffetService,
                FacilityEnumeratedConstants.CAFETERIA_TYPE.counterService,
                FacilityEnumeratedConstants.CAFETERIA_TYPE.tableService,
              ],
              scoring: [
              ],
            }
          },
          maximumScore: 0,

          spreadsheetRow: null,
          dependant: {
            questionKey: 'BkzJT', // Is there a cafeteria on the premises?
          },
        },
        {
          key: 'Mpj3F',
          label: 'What is the height of the food counters from the floor to the top of the counter? ',
          helpImage: null,
          helpText: 'The maximum height of a food counter should be no higher than 86cm (34 in) so someone in a seated position or someone of short stature can see and order whatever food or drinks are on offer.',
          popupLabel: 'Counter height',
          type: AppQuestion.TYPE_INTEGER,
          type2: {
            typeInteger: {
              minValue: 0,
              maxValue: Number.MAX_VALUE,
              scoring: [
                {
                  greaterThanOrEqualTo: 0,
                  lessThan: 87,
                  score: 2,
                }
              ]
            }
          },
          maximumScore: 2,

          spreadsheetRow: null,
          dependant: {
            questionKey: 'Mpj27', // What type of cafeteria service is provided?
            truthyEnumCodes: [
              FacilityEnumeratedConstants.CAFETERIA_TYPE.counterService.codeAsNumber,
              FacilityEnumeratedConstants.CAFETERIA_TYPE.buffetService.codeAsNumber,
              FacilityEnumeratedConstants.CAFETERIA_TYPE.selfService.codeAsNumber
            ]

          },
        },

        {
          key: 'BkzJn',
          label: 'Are the tables and chairs movable or fixed to the floor?\n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/48.BkzJn.svg'],
          helpText: 'Some of the tables and chairs should be movable if necessary to make room for a wheelchair user or other mobility devices.',
          popupLabel: 'Cafeteria tables & chairs',
          type: AppQuestion.TYPE_ENUM,
          type2: {
            typeEnum: {
              options: [
                FacilityEnumeratedConstants.FURNITURE_TYPE.movable,
                FacilityEnumeratedConstants.FURNITURE_TYPE.fixed,
                FacilityEnumeratedConstants.FURNITURE_TYPE.both,
                FacilityEnumeratedConstants.FURNITURE_TYPE.notApplicable,
              ],
              scoring: [
                {
                  codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.movable.codeAsNumber,
                  score: 2,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.fixed.codeAsNumber,
                  score: 0,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.both.codeAsNumber,
                  score: 1,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.notApplicable.codeAsNumber,
                  score: 2,
                },
              ]
            }
          },
          maximumScore: 2,

          spreadsheetRow: 57,
          dependant: {
            questionKey: 'BkzJT' // Is there a cafeteria on the premises?
          },
        },
        {
          key: 'Mpj3t',
          label: 'Is there 105cm wide aisle space between some tables and chairs?',
          helpImage: null,
          helpImages: [],
          helpText: 'There should be a 105cm wide aisle space between tables and chairs to allow a wheelchair user or someone with a mobility aid pass through without obstruction.',
          popupLabel: 'Wide aisle space',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 3,
                onFalse: 0,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: null,
          dependant: {
            questionKey: 'BkzJT' // Is there a cafeteria on the premises?
          },
        },

        {
          key: 'BkzJx',
          label: 'Do some tables have at least 70cm clear space underneath to accommodate a wheelchair user?\n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/51.BkzJx.svg'],
          helpText: 'The table should have clear space of 70cm beneath, with legs at the four corners of the table, or of a pod-type, with one central support. \n',
          popupLabel: 'Clear space under cafeteria tables',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 58,
          dependant: {
            questionKey: 'BkzJT' // Is there a cafeteria on the premises?
          }
        },
        {
          key: 'Mpj33',
          label: 'Are there food display cabinets or shelves where food and drink products are on display for purchase?',
          helpImage: null,
          helpImages: [],
          helpText: 'Display cabinets are a convienient way to present food and drink items for people to pick what they want.',
          popupLabel: '',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 0,
                onFalse: 0,
              }
            }
          },
          maximumScore: 0,

          spreadsheetRow: null,
          dependant: {
            questionKey: 'BkzJT' // Is there a cafeteria on the premises?
          }
        },
        {
          key: 'Mpj4B',
          label: 'Are the food and drink products displayed vertically on shelves and in cabinets?',
          helpImage: null,
          helpImages: [],
          helpText: 'At least 50% of each type of product must be within reach of all customers. Displaying brand products in vertical columns allows greater access to items. E.g a wheelchair user or someone of shorter stature can choose across the range of items rather than needing to reach up or ask for assistance to get a product.',
          popupLabel: 'Vertical Food Display',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 3,
                onFalse: 0,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: null,
          dependant: {
            questionKey: 'Mpj33' // Are there food display cabinets or shelves where food and drink products are on display for purchase?
          }
        },
        {
          key: 'BkzJ7',
          label: 'Please take 3 photos of the cafeteria from different angles (in landscape mode)',
          helpImage: null,
          helpText: '',
          popupLabel: '',
          type: AppQuestion.TYPE_PHOTO,
          maximumScore: 0,

          spreadsheetRow: 59,
          dependant: {
            questionKey: 'BkzJT'
          }
        },
        {
          key: 'Mpj4L',
          label: 'Are there vending machines in the building?',
          helpImage: null,
          helpImages: [],
          helpText: 'A vending machine is an automatic despenser for drinks, snacks and other food items. ',
          popupLabel: 'Vending machine',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 0,
                onFalse: 0,
              }
            }
          },
          maximumScore: 0,

          spreadsheetRow: null,
        },
        {
          key: 'Mpj4V',
          label: 'Is the control/panel on the vending machine accessible from a seated position?',
          helpImage: null,
          helpImages: [],
          helpText: 'The controls, buttons and payment slots on the vending machine should be set at a height between 38cm and 122cm.',
          popupLabel: 'Vending machine accessible',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 1,
                onFalse: 0,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: null,
        },


        {
          key: 'CmgRH',
          label: 'Is there a breakout area with refreshment facilities in the building? (Kitchenette, water coolers, coffee machines, vending machines etc.)',
          helpImage: null,
          helpText: 'Office breakout areas are spaces open to employees or visitors that are separate from their working area. It can be a place for employees to relax, eat their lunch, avail of refreshments and even hold informal meetings. ',
          popupLabel: 'Breakout area with refreshment facilities ',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 0,
                onFalse: 0,
              }
            }
          },
          maximumScore: 0,

          spreadsheetRow: -1,
        },
        {
          key: 'Cmgc1',
          label: 'Is there step free access into some of the breakout areas? This could be level, ramp or lift access.',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/52.Cmgc1.svg'],
          helpText: 'Is there a way to gain access to the breakout area that doesn’t involve steps? This could be level access or via a ramp?',
          popupLabel: 'Step free breakout room access',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 3,
                onFalse: 0,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'CmgRH'
          }
        },
        {
          key: 'CmgpB',
          label: 'Are appliances placed at a height reachable from a seated position in the most accessible breakout area? (Microwave, kettle, etc.)',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/53.CmgpB.svg'],
          helpText: 'Are kitchen appliances within easy reach for someone in a seated position or persons of shorter stature? Recommended accessible work surfaces should be min 28”- max 34” above the floor.',
          popupLabel: 'Appliances at lower height in breakout area',
          type: AppQuestion.TYPE_TERNARY,
          type2: {
            typeTernary: {
              scoring: {
                onNotApplicable: 1,
                onTrue: 1,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'CmgRH'
          }
        },
        {
          key: 'CmgyZ',
          label: 'Can the sink be reached from a seated position in the most accessible breakout area? ',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/55.CmgyZ.svg'],
          helpText: 'Is the kitchen sink at a suitable height and have adequate space underneath or beside the sink, to allow approach, reach and use for persons using a wheelchair and persons of shorter stature?',
          popupLabel: 'Sink at lower height in breakout area',
          type: AppQuestion.TYPE_TERNARY,
          type2: {
            typeTernary: {
              scoring: {
                onNotApplicable: 1,
                onTrue: 1,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'CmgRH'
          }
        },
        {
          key: 'Cmg8t',
          label: 'Can some cabinets or cupboards be reached from a seated position in the most accessible breakout area?  ',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/54.Cmg8t.svg'],
          helpText: 'Are some cabinets or storage cupboards set at a lower height or underneath the countertop?',
          popupLabel: 'Cupboards/cabinets at lower height in breakout area',
          type: AppQuestion.TYPE_TERNARY,
          type2: {
            typeTernary: {
              scoring: {
                onNotApplicable: 1,
                onTrue: 1,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'CmgRH'
          }
        },
        {
          key: 'CmhaZ', // Is the furniture moveable or fixed to the floor in the most accessible breakout area? (eg. Tables and chairs)
          label: 'Is the furniture moveable or fixed to the floor in the most accessible breakout area? (eg. Tables and chairs)',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/56.CmhaZ.svg'],
          helpText: 'Can some of the tables and chairs be moved if necessary, to make room for a wheelchair user? ',
          popupLabel: 'Tables & chairs in breakout area',
          type: AppQuestion.TYPE_ENUM,
          type2: {
            typeEnum: {
              options: [
                FacilityEnumeratedConstants.NOT_APPLICABLE,
                FacilityEnumeratedConstants.FURNITURE_TYPE.movable,
                FacilityEnumeratedConstants.FURNITURE_TYPE.fixed,
                FacilityEnumeratedConstants.FURNITURE_TYPE.both,
              ],
              scoring: [
                {
                  codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.movable.codeAsNumber,
                  score: 2,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.fixed.codeAsNumber,
                  score: 0,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.both.codeAsNumber,
                  score: 1,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.NOT_APPLICABLE.codeAsNumber,
                  score: 2,
                },
              ],
            }
          },
          maximumScore: 2,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'CmgRH'
          }
        },
        {
          key: 'Cmie_',
          label: 'Are appliances visually contrasting with countertops in the most accessible breakout area? ',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/53.Cmie_.svg'],
          helpText: 'Are appliances such as kettles, microwave, coffee machines etc. easy to differentiate from the background and surfaces. All users and especially persons with vision impairments benefit from visually contrasting kitchen elements to locate devices and easily use the facility.',
          popupLabel: 'Appliances colour contrast with countertops in breakout area',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 1,
                onFalse: 0,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'CmgRH'
          }
        },
        {
          key: 'QZ_lT',
          label: 'Is there a bar or lounge that serves alcoholic beverages in the office?',
          helpImage: null,
          helpImages: [],
          helpText: 'Is there a bar area that serves alcoholic beverages in the office?',
          popupLabel: 'Bar/Lounge Area',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 0,
                onFalse: 0,
              }
            }
          },
          maximumScore: 0,

          spreadsheetRow: -1,
        },
        {
          key: 'QZ_ob',
          label: 'Is the bar or lounge accessible to a wheelchair user? This could be level access, ramp access or via a lift.',
          helpImage: null,
          helpImages: [],
          helpText: 'Can the bar/drinks area be accessed without the need to use steps?',
          popupLabel: 'Level or ramped access to bar area',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'QZ_lT' // 'Is there a bar or lounge that serves alcoholic beverages in the office?'
          },
        },
        {
          key: 'QZ_pD',
          label: 'Is any part of the bar counter set at a lower height?',
          helpImage: null,
          helpImages: [],
          helpText: 'Is any part of the bar counter at a lower level for children, wheelchair users and people of short stature?',
          popupLabel: 'Lower counter at bar',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 1,
                onFalse: 0,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'QZ_lT' // 'Is there a bar or lounge that serves alcoholic beverages in the office?'
          },
        },
        {
          key: 'QZ_p_',
          label: 'In the bar/lounge area, are the tables and chairs movable or fixed to the floor?',
          helpImage: null,
          helpImages: [],
          helpText: 'Is there flexible seating areas where furniture can be moved around if necessary to make room for a wheelchair user or other mobility devices?',
          popupLabel: 'tables & chairs',
          type: AppQuestion.TYPE_ENUM,
          type2: {
            typeEnum: {
              options: [
                FacilityEnumeratedConstants.FURNITURE_TYPE.movable,
                FacilityEnumeratedConstants.FURNITURE_TYPE.fixed,
                FacilityEnumeratedConstants.FURNITURE_TYPE.both,
              ],
              scoring: [
                {
                  codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.movable.codeAsNumber,
                  score: 2,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.fixed.codeAsNumber,
                  score: 0,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.both.codeAsNumber,
                  score: 1,
                },
              ],
            }
          },
          maximumScore: 2,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'QZ_lT' // 'Is there a bar or lounge that serves alcoholic beverages in the office?'
          },
        },
        {
          key: 'QZ_qx',
          label: 'Are lower tables provided where wheelchair users can rest their plates and drinks?',
          helpImage: null,
          helpImages: [],
          helpText: 'A range of table height\'s should be available for any seated or standing user.',
          popupLabel: '',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 1,
                onFalse: 0,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'QZ_lT' // 'Is there a bar or lounge that serves alcoholic beverages in the office?'
          },
        },
        {
          key: 'QZ_rZ',
          label: 'Is there a clear width between tables and chairs allow a room for a wheelchair user to pass?',
          helpImage: null,
          helpImages: [],
          helpText: 'Clear routes (90cm minimum) – between the tables and a choice of seating type will accommodate a wide range of users.',
          popupLabel: '',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'QZ_lT' // 'Is there a bar or lounge that serves alcoholic beverages in the office?'
          },
        },
        {
          key: 'QZ_sf',
          label: 'Please take 2 photo of the most accessible bar or lounge area (in landscape mode)',
          helpImage: null,
          helpImages: [],
          helpText: 'Take 1 good quality photos of the accessible bar or lounge area',
          popupLabel: '',
          type: AppQuestion.TYPE_PHOTO,
          maximumScore: 0,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'QZ_lT' // 'Is there a bar or lounge that serves alcoholic beverages in the office?'
          },
        },

        {
          key: 'BkzKF',
          label: 'Are there flights of stairs within the building?\n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/58.BkzKF.svg'],
          helpText: 'This could be a staircase, a stairwell, or a flight of stairs between one floor or landing and the next.\n',
          popupLabel: 'Stairs in building',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 0,
                onFalse: 0,
              }
            }
          },
          maximumScore: 0,

          spreadsheetRow: 60,
        },
        {
          key: 'BkzKP',
          label: 'Do the stairs have handrails that colour contrast with the surrounding walls?\n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/59.BkzKP.svg'],
          helpText: 'Handrails that are a different colour and visually contrast with the surrounding floor can assist people with visual impairment. N/A means there are no handrails.',
          popupLabel: 'Stair handrails colour contrast with walls',
          type: AppQuestion.TYPE_TERNARY,
          type2: {
            typeTernary: {
              scoring: {
                onNotApplicable: 2,
                onTrue: 2,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 61,
          dependant: {
            questionKey: 'BkzKF'
          },
        },
        {
          key: 'BkzeF',
          label: 'Are the first and last steps clearly marked with a different color or tactile floor warning indicators?  ',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/59.BkzeF.svg'],
          helpText: 'Warning Tactile Indicator installed on the walking surface in a raised grid pattern of studs or \'dots\'  warn blind and vision-impaired pedestrians of nearby steps.\n',
          popupLabel: 'Floor warning indicators at stairs',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 3,
                onFalse: 0,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: 62,
          dependant: {
            questionKey: 'BkzKF'
          },
        },

        //
        //
        // Event Spaces (row: 63)
        //
        //

        {
          key: 'Bkzpp',
          label: 'Is there an auditorium in the building?\n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/60.Bkzpp.svg'],
          helpText: 'Is there an auditorium or a room built to enable an audience to hear and watch performances, lectures, talks and events?',
          popupLabel: 'Auditorium',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 0,
                onFalse: 0,
              }
            }
          },
          maximumScore: 0,

          spreadsheetRow: 64,
        },
        {
          key: 'Bkzpz',
          label: 'Is there a designated space for wheelchair users within the auditorium?      ',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/61.Bkzpz.svg'],
          helpText: 'Wheelchair accessible spaces should always be provided on a level surface and have clear unobstructed access from the aisle or access route. Recommended width 90cm x 140cm depth.',
          popupLabel: 'Auditorium wheelchair accessible spaces',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 97,
          dependant: {
            questionKey: 'Bkzpp',
          },
        },
        {
          key: 'Mpj4f',
          label: 'Is there a clear line of sight from the wheelchair designated space to the presenter?',
          helpImage: null,
          helpImages: [],
          helpText: 'A clear sight line means an unobstructed view from the designated wheelchair seating area to the stage / presenter could mean columns, pillars or people standing and blocking the line of sight.',
          popupLabel: '',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: null,
          dependant: {
            questionKey: 'Bkzpz', // Is there a designated space for wheelchair users within the auditorium?
          },
        },
        {
          key: 'Bkzp9',
          label: 'Is there an assistive listening system provided? (eg. audio induction loop) ',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/19.Bkzp9.png'],
          helpText: 'Acoustics within lecture and auditorium facilities should be conducive to clear verbal communication. Assistive listening devices (ADL) and hearing enhancement systems are frequently used in conjunction with hearing aids. For example induction loops and infrared signal transmitting systems help people who use a hearing aid or loop listener to hear sounds more clearly.',
          popupLabel: 'Auditorium assistive listening system',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 3,
                onFalse: 0,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: 66,
          dependant: {
            questionKey: 'Bkzpp',
          },
        },
        {
          key: 'BkzqH',
          label: 'Is there a text display or subtitles option on presentation screens? \n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/62.BkzqH.svg'],
          helpText: 'Adding subtitles or closed captions to screens makes your presentation accessible to a larger audience, including people with hearing disabilities and those who speak languages other than the one in your presentation.',
          popupLabel: 'Auditorium text display/subtitles',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 3,
                onFalse: 0,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: 67,
          dependant: {
            questionKey: 'Bkzpp', // Is there an auditorium in the building?
          },
        },
        {
          key: 'Mpj4p',
          label: 'Is there space for an assistance dog? ',
          helpImage: null,
          helpImages: [],
          helpText: 'Service dogs are trained working animals who are kept on a leash and need to accompany their owner in public areas. Providing a space for the dog in the auditorium allows the dog to stay close to their owner.',
          popupLabel: '',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: null,
          dependant: {
            questionKey: 'Bkzpp', // Is there an auditorium in the building?
          },
        },
        {
          key: 'Bkzqb',
          label: 'Please take 2 photos of the auditorium from different angles (in landscape mode)',
          helpImage: null,
          helpText: '',
          popupLabel: '',
          type: AppQuestion.TYPE_PHOTO,
          maximumScore: 0,

          spreadsheetRow: 68,
          dependant: {
            questionKey: 'Bkzpp', // Is there an auditorium in the building?
          },
        },
        {
          key: 'QZ_t5',
          label: 'Do you have any other comments or information about this area?',
          helpImage: null,
          helpText: '',
          popupLabel: '',
          type: AppQuestion.TYPE_TEXT,
          maximumScore: 0,

          spreadsheetRow: -1,
        },


        //
        //
        // Elevators/Lifts (row: 69)
        //
        //

        {
          key: 'Bkz__',
          label: 'Is there a lift/elevator in the building?\n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/63.Bkz__.svg'],
          helpText: 'Is there an elevator or lift accessible to all relevant areas in the building? N/A means your premises is a single story structure.',
          popupLabel: 'Lift/Elevator',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 0,
                onFalse: 0,
              }
            }
          },
          maximumScore: 0,

          spreadsheetRow: 70,
        },
        {
          key: 'Bk0AJ',
          label: 'Is there a step free route from the main accessible entrance of the building to the most accessible lift/elevator? This could be level, ramp or lift access.',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/64.Bk0AJ.svg'],
          helpText: 'Is the access route to the elevator free from barriers such as steps?\n',
          popupLabel: 'Step free route from entrance to lift/elevator',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 3,
                onFalse: 0,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: 71,
          dependant: {
            questionKey: 'Bkz__', // Is there a lift/elevator in the building?
          },
        },
        {
          key: 'Bk0AT',
          label: 'What is the "door width" of the most accessible lift/elevator? (In cm)  ',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/65.Bk0AT.svg'],
          helpText: 'In centimetres, measure the door width of lift - Measure the internal width of lift - Measure the internal depth of lift. ',
          popupLabel: 'Lift/elevator door width',
          type: AppQuestion.TYPE_CM_MEASUREMENT,
          type2: {
            typeCmMeasurement: {
              minValue: 60,
              maxValue: 4 * 1000,
              scoring: [
                {
                  greaterThanOrEqualTo: 90,
                  score: 1
                },
              ]
            }
          },
          maximumScore: 1,

          spreadsheetRow: 72,
          dependant: {
            questionKey: 'Bkz__', // Is there a lift/elevator in the building?
          },
        },
        {
          key: 'Bk0An',
          label: 'What is the "internal width" of the most accessible lift/elevator? (In cm)\n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/65.Bk0An.svg'],
          helpText: 'In centimetres, measure the door width of lift - Measure the internal width of lift - Measure the internal depth of lift.  ',
          popupLabel: 'Lift/elevator internal width',
          type: AppQuestion.TYPE_CM_MEASUREMENT,
          type2: {
            typeCmMeasurement: {
              minValue: 60,
              maxValue: 4 * 1000,
              scoring: [
                {
                  greaterThanOrEqualTo: 180,
                  score: 1
                },
              ]
            }
          },
          maximumScore: 1,

          spreadsheetRow: 73,
          dependant: {
            questionKey: 'Bkz__', // Is there a lift/elevator in the building?
          },
        },
        {
          key: 'Bk0Ax',
          label: 'What is the "internal depth" of the most accessible lift/elevator? (In cm)\n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/65.Bk0Ax.svg'],
          helpText: 'In centimetres, measure the door width of lift - Measure the internal width of lift - Measure the internal depth of lift. ',
          popupLabel: 'Lift/elevator internal depth',
          type: AppQuestion.TYPE_CM_MEASUREMENT,
          type2: {
            typeCmMeasurement: {
              minValue: 60,
              maxValue: 4 * 1000,
              scoring: [
                {
                  greaterThanOrEqualTo: 180,
                  score: 1
                },
              ]
            }
          },
          maximumScore: 1,

          spreadsheetRow: 74,
          dependant: {
            questionKey: 'Bkz__', // Is there a lift/elevator in the building?
          },
        },
        {
          key: 'Bk0A7',
          label: 'Are there voice announcements for each floor in the most accessible lift/elevator?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/66.Bk0A7.svg'],
          helpText: 'Is there spoken messages indicating floor number and other information to passengers in the elevator.  ',
          popupLabel: 'Voice announcements in lift/elevator',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 75,
          dependant: {
            questionKey: 'Bkz__', // Is there a lift/elevator in the building?
          },
        },
        {
          key: 'Bk0BF',
          label: 'Are there braille, raised numbers or appropriate symbols on the most accessible lift/elevator buttons?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/67.Bk0BF.svg'],
          helpText: 'Is there raised characters, braille and symbols on the elevator control panel for the benefit of people with visual impairments and sight loss to read numbers and buttons.',
          popupLabel: 'Raised numbers/letters/Braille on lift buttons',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 1,
                onFalse: 0,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: 76,
          dependant: {
            questionKey: 'Bkz__', // Is there a lift/elevator in the building?
          },
        },
        {
          key: 'CmlfV',
          label: 'Is there a colour contrasted alarm button in the most accessible lift/elevator?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/68.CmlfV.svg'],
          helpText: 'Elevator alarm button should colour contrast against the background i.e. yellow with bell shape symbol and shall be identified with tactile symbols and visible indicators.',
          popupLabel: 'Colour contrasted alarm button in lift/elevator',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 1,
                onFalse: 0,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'Bkz__', // Is there a lift/elevator in the building?
          }
        },
        {
          key: 'Bk0BP',
          label: 'Please take 1 photo of the most accessible lift/elevator with the doors open (in landscape mode)',
          helpImage: null,
          helpText: '',
          popupLabel: '',
          type: AppQuestion.TYPE_PHOTO,
          maximumScore: 0,

          spreadsheetRow: 77,
          dependant: {
            questionKey: 'Bkz__', // Is there a lift/elevator in the building?
          },
        },

      ],
      sections: {
        evaluation: [
          // #1
          {
            title: 'Stairs',
            questionKeys: [
              'BkzKF',
              'BkzKP',
              'BkzeF',
            ],
            subSections: [],
          },
          // #2
          {
            title: 'Lift/Elevator',
            questionKeys: [
              'Bkz__',
              'Bk0AJ',
              'Bk0A7',
              'Bk0BF',
              'CmlfV',
            ],
            subSections: [],
          },
          // #3
          {
            title: 'Lift/Elevator',
            questionKeys: [
              'Bk0AT',
              'Bk0An',
              'Bk0Ax',
            ],
            dependantDescriptor: {
              questionKey: 'Bkz__',
            },
            subSections: [],
          },
          // #4
          {
            title: 'Lift/Elevator',
            questionKeys: [
              'Bk0BP',
            ],
            dependantDescriptor: {
              questionKey: 'Bkz__',
            },
            subSections: [],
          },
          // #5
          {
            title: 'General facilities',
            questionKeys: [
              'BkyVv',
              'BkyV5',
              'BkyWD',
              'BkyWN',
            ],
            subSections: [],
          },
          // #6
          {
            title: 'General facilities',
            questionKeys: [
              'BkyWX',
              'BkyWh',
              'BkyWr',
              'BkyW1',
            ],
            subSections: [],
          },
          // #7
          {
            title: 'Routes/Corridors',
            questionKeys: [
              'Bky5D',
              'Bky5N',
            ],
            subSections: [],
          },
          // #8
          {
            title: 'Routes/Corridors',
            questionKeys: [
              'Bky5X',
            ],
            subSections: [],
          },
          // #9
          {
            title: 'Cafeteria',
            questionKeys: [
              'BkzJT',
              'BkzJd',
              'Mpj27',
              'Mpj3F',
              // 'Mpj3Z',
            ],
            subSections: [],
          },
          // #10
          {
            title: 'Cafeteria',
            questionKeys: [
              'BkzJn',
              'Mpj3t',
              'BkzJx',
              'Mpj33',
              'Mpj4B',
            ],
            dependantDescriptor: {
              questionKey: 'BkzJT',
            },
            subSections: [],
          },
          // #11
          {
            title: 'Cafeteria',
            questionKeys: [
              'BkzJ7',
            ],
            dependantDescriptor: {
              questionKey: 'BkzJT',
            },
            subSections: [],
          },
          // #12
          {
            title: 'Breakout Areas',
            questionKeys: [
              'Mpj4L',
              'Mpj4V',
            ],
            subSections: [],
          },
          // #13
          {
            title: 'Breakout Areas',
            questionKeys: [
              'CmgRH',
              'Cmgc1',
              'CmgpB',
            ],
            subSections: [],
          },
          // #14
          {
            title: 'Breakout Areas',
            questionKeys: [
              'CmgyZ',
              'Cmg8t',
              'CmhaZ',
              'Cmie_',
            ],
            dependantDescriptor: {
              questionKey: 'CmgRH',
            },
            subSections: [],
          },
          // #15
          {
            title: 'Bar Area',
            questionKeys: [
              'QZ_lT', // Is there a bar or lounge that serves alcoholic beverages in the office?
              'QZ_ob',
              'QZ_pD',
            ],
            subSections: [],
          },

          // #16
          {
            title: 'Bar Area',
            questionKeys: [
              'QZ_p_',
              'QZ_qx',
              'QZ_rZ',
            ],
            dependantDescriptor: {
              questionKey: 'QZ_lT' // Is there a bar or lounge that serves alcoholic beverages in the office?
            },
            subSections: [],
          },

          // #17
          {
            title: 'Bar Area',
            questionKeys: [
              'QZ_sf', // Please take 2 photo of the most accessible bar or lounge area (in landscape mode)
            ],
            dependantDescriptor: {
              questionKey: 'QZ_lT' // Is there a bar or lounge that serves alcoholic beverages in the office?
            },
            subSections: [],
          },

          // #18
          {
            title: 'Auditorium',
            questionKeys: [
              'Bkzpp',
              'Bkzpz',
              'Mpj4f',
            ],
            subSections: [],
          },
          // #19
          {
            title: 'Auditorium',
            questionKeys: [
              'Bkzp9',
              'BkzqH',
              'Mpj4p',
            ],
            dependantDescriptor: {
              questionKey: 'Bkzpp' // Is there an auditorium in the building?
            },
            subSections: [],
          },
          // #20
          {
            title: 'Auditorium',
            questionKeys: [
              'Bkzqb',
            ],
            dependantDescriptor: {
              questionKey: 'Bkzpp' // Is there an auditorium in the building?
            },
            subSections: [],
          },
          // #21
          {
            title: 'Any other comments or feedback',
            questionKeys: [
              'QZ_t5',
            ],
            subSections: [],
          }

        ],
        portal: [
        ],
      },
      portal: {
        navigationMenu: {
          iconImgBase: 'assets/service.navigation-menu/fontawesome.building-regular'
        }
      },
      spreadsheetRow: 41,
      uuid: 'd70d55bd-1adc-4ab8-a2a4-452700a749f1'
    }),
    new AppAspectDefinition({
      name: 'Work Stations & Meeting/Training Rooms',
      // firebaseAnswersRoot: '/answers-x/facilities',
      firebaseAspectId: 'rooms-and-work-stations',
      questions: [

        //
        //
        // Meeting rooms (row: 78)
        //
        //

        {
          key: 'Bk0fj',
          label: 'Do you have meeting rooms on the premises?\n',
          helpImage: null,
          helpText: 'Is there a designated serviced room to hold meetings?\n',
          popupLabel: '',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 0,
                onFalse: 0,
              }
            }
          },
          maximumScore: 0,

          spreadsheetRow: 79,
        },
        {
          key: 'Bk0ft',
          label: 'How many wheelchair accessible meeting rooms are there on the premises?\n',
          helpImage: null,
          helpText: 'Accessible meeting rooms have easy access with wide doorways and movable furniture',
          popupLabel: 'Wheelchair accessible meeting rooms',
          type: AppQuestion.TYPE_INTEGER,
          type2: {
            typeInteger: {
              minValue: 1,
              maxValue: Number.MAX_VALUE,
              scoring: [
                {
                  greaterThanOrEqualTo: 1,
                  score: 3,
                }
              ]
            }
          },
          maximumScore: 3,

          spreadsheetRow: 80,
          dependant: {
            questionKey: 'Bk0fj', // Do you have meeting rooms on the premises?
          },
        },
        {
          key: 'Bk0f3',
          label: 'Is there braille, raised lettering or appropriate symbols on the room signage?\n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/75.Bk0f3.svg'],
          helpText: 'Are there any signs on meeting rooms that have tactile letters and or braille to help people with vision impairments identify these rooms?',
          popupLabel: 'Raised numbers/letters/Braille on meeting room signage',
          type: AppQuestion.TYPE_TERNARY,
          type2: {
            typeTernary: {
              scoring: {
                onNotApplicable: 2,
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 81,
          dependant: {
            questionKey: 'Bk0fj', // Do you have meeting rooms on the premises?
          },
        },
        {
          key: 'Bk0gB',
          label: 'What is the door width measurement of the most accessible meeting room? (In cm)\n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/17.Bk0gB.jpg'],
          helpText: 'Please measure the door width of the best accessible meeting room.  (Doorway should provide a minimum clear opening width of 81cm). ',
          popupLabel: 'Wheelchair accessible room door width',
          type: AppQuestion.TYPE_CM_MEASUREMENT,
          type2: {
            typeCmMeasurement: {
              minValue: 60,
              maxValue: 4 * 1000,
              scoring: [
                {
                  greaterThanOrEqualTo: 81,
                  lessThan: 91,
                  score: 2
                },
                {
                  greaterThanOrEqualTo: 91,
                  score: 3
                },
              ]
            }
          },
          maximumScore: 3,

          spreadsheetRow: 82,
          dependant: {
            questionKey: 'Bk0ft', // How many wheelchair accessible meeting rooms are there on the premises?
          },
        },
        {
          key: 'Bk0gL',
          label: 'Can the door to the most accessible meeting room be opened by someone with limited strength?\n',
          helpImage: null,
          helpText: 'Interior accessible doors should require no more than 5 lbs (22.2N) of force to open. Note: You can use a pressure gauge or fish scale to measure force. If you do not have one you will need to judge whether the door is easy to open.  (N/A to this question means there is no door).',
          popupLabel: 'Meeting rooms with easy open doors',
          type: AppQuestion.TYPE_TERNARY,
          type2: {
            typeTernary: {
              scoring: {
                onNotApplicable: 1,
                onTrue: 1,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: 83,
          dependant: {
            questionKey: 'Bk0ft', // How many wheelchair accessible meeting rooms are there on the premises?
          },
        },
        {
          key: 'Bk0gV',
          label: 'Is the furniture moveable or fixed to the floor in the most accessible meeting room? (eg. Tables and chairs)',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/76.Bk0gV.svg'],
          helpText: 'Can some furniture be moved to make space for a wheelchair user?  Tables should have legs that do not protrude into the circulation area or block access for a person using a wheelchair to position beneath the table.',
          popupLabel: 'Furniture in wheelchair accessible meeting room ',
          type: AppQuestion.TYPE_ENUM,
          type2: {
            typeEnum: {
              options: [
                FacilityEnumeratedConstants.NOT_APPLICABLE,
                FacilityEnumeratedConstants.FURNITURE_TYPE.movable,
                FacilityEnumeratedConstants.FURNITURE_TYPE.fixed,
                FacilityEnumeratedConstants.FURNITURE_TYPE.both,
              ],
              scoring: [
                {
                  codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.movable.codeAsNumber,
                  score: 2,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.fixed.codeAsNumber,
                  score: 0,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.FURNITURE_TYPE.both.codeAsNumber,
                  score: 1,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.NOT_APPLICABLE.codeAsNumber,
                  score: 0,
                },
              ],
            }
          },
          maximumScore: 2,

          spreadsheetRow: 84,
          dependant: {
            questionKey: 'Bk0ft', // How many wheelchair accessible meeting rooms are there on the premises?
          },
        },
        {
          key: 'Bk0gf',
          label: 'Do you have an assistive listening device available in any of the meeting rooms? (eg. Audio induction loop)',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/19.Bk0gf.png'],
          helpText: 'Assistive listening devices (ADL) and hearing enhancement systems are frequently used in conjunction with hearing aids. For example induction loops and infrared signal transmitting systems help people who use a hearing aid or loop listener to hear sounds more clearly.',
          popupLabel: 'Meeting rooms with assistive listening system',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 3,
                onFalse: 0,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: 85,
          dependant: {
            questionKey: 'Bk0fj', // Do you have meeting rooms on the premises?
          },
        },
        {
          key: 'Cmlsx',
          label: 'Do you have captioned performance or speech-to-text subtitles available on screen displays in any of the meeting rooms?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/62.Cmlsx.svg'],
          helpText: '',
          popupLabel: 'Meeting room screens with text display/subtitles',
          type: AppQuestion.TYPE_TERNARY,
          type2: {
            typeTernary: {
              scoring: {
                onNotApplicable: 3,
                onTrue: 3,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'Bk0fj'
          }
        },

        {
          key: 'Bk0gp',
          label: 'Please take 1 photo of the most accessible meeting room (in landscape mode)',
          helpImage: null,
          helpText: '',
          popupLabel: '',
          type: AppQuestion.TYPE_PHOTO,
          maximumScore: 0,

          spreadsheetRow: 86,
          dependant: {
            questionKey: 'Bk0ft', // How many wheelchair accessible meeting rooms are there on the premises?
          },
        },

        //
        //
        // Training Rooms (row: 87)
        //
        //

        {
          key: 'Bk1dH',
          label: 'Do you have training rooms which are separate from your meeting rooms?\n',
          helpImage: null,
          helpText: 'A training session generally occurs in a larger room, with several attendees. It requires clear and unobstructed views of the head of the session as well as of any screens and other visual aids used.\n',
          popupLabel: 'Training rooms',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 0,
                onFalse: 0,
              }
            }
          },
          maximumScore: 0,

          spreadsheetRow: 88,
        },
        {
          key: 'Bk1dR',
          label: 'How many wheelchair accessible training rooms are there on the premises?\n',
          helpImage: null,
          helpText: 'How many training rooms have easy access with wide doorways and movable furniture?\n',
          popupLabel: 'Wheelchair accessible training rooms',
          type: AppQuestion.TYPE_INTEGER,
          type2: {
            typeInteger: {
              minValue: 1,
              maxValue: Number.MAX_VALUE,
              scoring: [
                {
                  greaterThanOrEqualTo: 1,
                  score: 3,
                }
              ]
            }
          },
          maximumScore: 3,

          spreadsheetRow: 89,
          dependant: {
            questionKey: 'Bk1dH', //Do you have training rooms which are separate from your meeting rooms?
          },
        },
        {
          key: 'Bk1db',
          label: 'Is there braille, raised lettering or appropriate symbols on the room signage?\n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/75.Bk1db.svg'],
          helpText: 'Are there any signs on training rooms that have tactile letters and or braille to help people with vision impairments identify these rooms?',
          popupLabel: 'Braille/raised lettering/appropriate symbols on meeting room signage ',
          type: AppQuestion.TYPE_TERNARY,
          type2: {
            typeTernary: {
              scoring: {
                onNotApplicable: 2,
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 90,
          dependant: {
            questionKey: 'Bk1dH', //Do you have training rooms which are separate from your meeting rooms?
          },
        },
        {
          key: 'Bk1dl',
          label: 'What is the width of the most accessible training room door? (In cm) \n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/18.Bk1dl.jpg'],
          helpText: 'Please measure the door width of the best accessible training room.  (Doorway should provide a minimum clear opening width of 81cm).',
          popupLabel: 'Wheelchair accessible training room door width',
          type: AppQuestion.TYPE_CM_MEASUREMENT,
          type2: {
            typeCmMeasurement: {
              minValue: 60,
              maxValue: 4 * 1000,
              scoring: [
                {
                  greaterThanOrEqualTo: 81,
                  lessThan: 91,
                  score: 2
                },
                {
                  greaterThanOrEqualTo: 90,
                  score: 3
                },
              ]
            }
          },
          maximumScore: 3,

          spreadsheetRow: 91,
          dependant: {
            questionKey: 'Bk1dR', // How many wheelchair accessible training rooms are there on the premises?
          },
        },
        {
          key: 'Bk1dv',
          label: 'Can the door to the most accessible training room be opened by someone with limited strength?\n',
          helpImage: null,
          helpText: 'Interior accessible doors should require no more than 5 lbs (22.2N) of force to open. Note: You can use a pressure gauge or fish scale to measure force. If you do not have one you will need to judge whether the door is easy to open.  (N/A to this question means there is no door).',
          popupLabel: 'Training rooms with easy open doors',
          type: AppQuestion.TYPE_TERNARY,
          type2: {
            typeTernary: {
              scoring: {
                onNotApplicable: 1,
                onTrue: 1,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: 92,
          dependant: {
            questionKey: 'Bk1dR', // How many wheelchair accessible training rooms are there on the premises?
          },
        },
        {
          key: 'Bk1d5',
          label: 'Is the furniture moveable or fixed to the floor in the most accessible training room? (eg. Tables and chairs)',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/76.Bk1d5.svg'],
          helpText: 'Can some furniture be moved to make space for a wheelchair user?  Tables should have legs that do not protrude into the circulation area or block access for a person using a wheelchair to position beneath the table.',
          popupLabel: 'Furniture in wheelchair accessible training room',
          type: AppQuestion.TYPE_ENUM,
          type2: {
            typeEnum: {
              options: [
                FacilityEnumeratedConstants.FURNITURE_TYPE.movable,
                FacilityEnumeratedConstants.FURNITURE_TYPE.fixed,
                FacilityEnumeratedConstants.FURNITURE_TYPE.both,
                FacilityEnumeratedConstants.NOT_APPLICABLE,
              ],
              scoring: [
                {
                  codeAsNumber:  FacilityEnumeratedConstants.FURNITURE_TYPE.movable.codeAsNumber,
                  score: 2,
                },
                {
                  codeAsNumber:  FacilityEnumeratedConstants.FURNITURE_TYPE.fixed.codeAsNumber,
                  score: 0,
                },
                {
                  codeAsNumber:  FacilityEnumeratedConstants.FURNITURE_TYPE.both.codeAsNumber,
                  score: 1,
                },
                {
                  codeAsNumber:  FacilityEnumeratedConstants.NOT_APPLICABLE.codeAsNumber,
                  score: 0,
                },
              ]
            }
          },
          maximumScore: 2,

          spreadsheetRow: 93,
          dependant: {
            questionKey: 'Bk1dR', // How many wheelchair accessible training rooms are there on the premises?
          },
        },
        {
          key: 'Bk1eD',
          label: 'Do you have an assistive listening device available in any of the training rooms? (eg. Audio induction loop)',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/19.Bk1eD.png'],
          helpText: 'Assistive listening devices (ADL) and hearing enhancement systems are frequently used in conjunction with hearing aids. For example induction loops and infrared signal transmitting systems help people who use a hearing aid or loop listener to hear sounds more clearly.',
          popupLabel: 'Training rooms with assistive listening system',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 3,
                onFalse: 0,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: 94,
          dependant: {
            questionKey: 'Bk1dR', // How many wheelchair accessible training rooms are there on the premises?
          },
        },
        {
          key: 'Bk1eN',
          label: 'Do you have captioned performance or speech-to-text subtitles available on screen displays in any of the training rooms?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/62.Bk1eN.svg'],
          helpText: 'Subtitles are on-screen representations of dialogue taking place on video. Subtitles or closed captioning is not just for people with hearing difficulties, it helps viewers understand the flow of the conversations especially when there are a lot of noise and other distractions in the background. \n',
          popupLabel: 'Training room screens with text display/subtitles',
          type: AppQuestion.TYPE_TERNARY,
          type2: {
            typeTernary: {
              scoring: {
                onNotApplicable: 3,
                onTrue: 3,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: 95,
          dependant: {
            questionKey: 'Bk1dR', // How many wheelchair accessible training rooms are there on the premises?
          },
        },
        {
          key: 'Bk1eX',
          label: 'Please take 1 photo of the most accessible training room (in landscape mode)',
          helpImage: null,
          helpText: '',
          popupLabel: '',
          type: AppQuestion.TYPE_PHOTO,
          maximumScore: 0,

          spreadsheetRow: 96,
          dependant: {
            questionKey: 'Bk1dR', // How many wheelchair accessible training rooms are there on the premises?
          },
        },
        {
          key: 'QZ_yl',
          label: 'Do you have any other comments or information about this area?',
          helpImage: null,
          helpText: '',
          popupLabel: '',
          type: AppQuestion.TYPE_TEXT,
          maximumScore: 0,

          spreadsheetRow: -1,
        },

        //
        //
        // Office Workstations (row: 97)
        //
        //

        {
          key: 'Bk2BD',
          label: 'Are there different floor surfaces around open-plan areas and workstations that can aid orientation for people with visual impairments?',
          helpImage: null,
          helpText: 'Different floor surfaces and visual contrasting designs can aid orientation and ease of passage for people with sight impairment and wheelchair users. Floor surfaces should be firm, slip-resistant and not cause reflection or glare. (Ref: 2010 ADA Standards 4.5 Ground and Floor Surfaces).',
          popupLabel: 'Contrasting floor surfaces for orientation in office workstations',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 98,
        },
        {
          key: 'Bk2BN',
          label: 'Does the office furniture colour contrast with their surrounding surfaces such as the walls and floor?  (couches, chairs, tables etc.) ',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/70.Bk2BN.svg'],
          helpText: 'Strong colour contrast between two items is especially helpful for people who are visually impaired and can help them navigate their surroundings.',
          popupLabel: 'Colour contrasting furniture in office workstations',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 99,
        },
        {
          key: 'Bk2BX',
          label: 'Are some office desk electrical sockets at a reachable height for someone in a seated position? ',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/72.Bk2BX.svg'],
          helpText: 'Ease of reach is important for a wide range of people. Electrical wall sockets should be located at a minimum of 40cm and a maximum 100cm above floor level and 50cm from any corner.',
          popupLabel: 'Electrical switches/outlets/sockets at a lower height in office workstations',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 1,
                onFalse: 0,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: 100,
        },
        {
          key: 'Bk2Bh',
          label: 'Are some office desk electrical sockets a different colour to the surrounding background wall on which they are mounted?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/72.Bk2Bh.svg'],
          helpText: 'Are some electrical switches, outlets and sockets a different colour to the surrounding background against which they are seen?',
          popupLabel: 'Colour contrasting electrical switches/outlets/sockets in office workstations',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 1,
                onFalse: 0,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: 101,
        },
        {
          key: 'Bk2Br',
          label: 'Are some office desks height adjustable?\n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/71.Bk2Br.svg'],
          helpText: 'ADA recommend a minimum clearance of 68cm under work surface/desk. Consider work surfaces that are flexible and permit installation at variable heights and clearances. The provision of height-adjustable desks should be an option.',
          popupLabel: 'Height adjustable desks in office workstations',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 3,
                onFalse: 0,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: 102,
        },
        {
          key: 'Bk2B1',
          label: 'Please take 2 photos of the most accessible office workstation area (in landscape mode)\n',
          helpImage: null,
          helpText: '',
          popupLabel: '',
          type: AppQuestion.TYPE_PHOTO,
          maximumScore: 0,

          spreadsheetRow: 103,
        },
        {
          key: 'Bk2B_',
          label: 'Do some print stations have adequate space for a wheelchair user?\n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/74.Bk2B_.svg'],
          helpText: 'The preferred height for operation of a photocopying machine by a person in a seated position is 76cm from floor level. A minimum of 50cm from a corner. ',
          popupLabel: 'Wheelchair accessible printers in office workstations',
          type: AppQuestion.TYPE_TERNARY,
          type2: {
            typeTernary: {
              scoring: {
                onNotApplicable: 1,
                onTrue: 1,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: 104,
        },
        {
          key: 'Mpj4z',
          label: 'Are there designated quiet areas or rooms in the building?',
          helpImage: null,
          helpImages: [],
          helpText: 'A quiet room is a calm room separate from the noise of the office. Ideally they should offer a relaxing tranquil space with soft ambient lighting. ',
          popupLabel: 'Designated quiet areas',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: null,
//          dependant: {
//            questionKey: '',
//          },
        },




      ],
      sections: {
        evaluation: [
          // #1
          {
            title: 'Workstations',
            questionKeys: [
              'Bk2BD',
              'Bk2BN',
              'Bk2Br',
            ],
            subSections: [],
          },
          // #2
          {
            title: 'Workstations',
            questionKeys: [
              'Bk2BX',
              'Bk2Bh',
              'Bk2B_',
              'Mpj4z',
            ],
            subSections: [],
          },
          // #3
          {
            title: 'Workstations',
            questionKeys: [
              'Bk2B1'
            ],
            subSections: [],
          },
          // #4
          {
            title: 'Meeting Rooms',
            questionKeys: [
              'Bk0fj',
              'Bk0ft',
              'Bk0f3',
              'Bk0gB',
            ],
            subSections: [],
          },
          // #5
          {
            title: 'Meeting Rooms',
            questionKeys: [
              'Bk0gL',
              'Bk0gV',
              'Bk0gf',
              'Cmlsx',
            ],
            dependantDescriptor: {
              questionKey: 'Bk0fj',
            },
            subSections: [],
          },
          // #6
          {
            title: 'Meeting Rooms',
            questionKeys: [
              'Bk0gp'
            ],
            dependantDescriptor: {
              questionKey: 'Bk0fj',
            },
            subSections: [],
          },
          // #7
          {
            title: 'Training Rooms',
            questionKeys: [
              'Bk1dH',
              'Bk1dR',
              'Bk1db',
              'Bk1dl',
            ],
            subSections: [],
          },
          // #8
          {
            title: 'Training Rooms',
            questionKeys: [
              'Bk1dv',
              'Bk1d5',
              'Bk1eD',
              'Bk1eN',
            ],
            dependantDescriptor: {
              questionKey: 'Bk1dH',
            },
            subSections: [],
          },
          // #9
          {
            title: 'Training Rooms',
            questionKeys: [
              'Bk1eX'
            ],
            dependantDescriptor: {
              questionKey: 'Bk0fj',
            },
            subSections: [],
          },
          // #10
          {
            title: 'Any other comments or feedback',
            questionKeys: [
              'QZ_yl',
            ],
            subSections: [],
          }

        ],
        portal: [
        ],
      }
      ,
      portal: {
        navigationMenu: {
          iconImgBase: 'assets/service.navigation-menu/fontawesome.building-regular'
        }
      },
      spreadsheetRow: 78,
      uuid: '742f0bcf-6625-43db-baaa-dc24b5997eb4'
    }),
    new AppAspectDefinition({
      name: 'Bathrooms for Employees',
      // firebaseAnswersRoot: '/answers-x/facilities',
      firebaseAspectId: 'accessible-bathrooms',
      questions: [


        {
          key: 'Bkvf3',
          label: 'Do the general bathrooms have braille, raised lettering or appropriate symbols to identify the bathroom type? ',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/24.Bkvf3.png'],
          helpText: 'Braille is a form of written language for blind people, in which characters are represented by patterns of raised dots that are felt with the fingertips. Raised (tactile) male/female/unisex/accessibility symbol for bathroom. ',
          popupLabel: 'Raised numbers/letters/Braille on bathroom door',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 28,
        },

        //
        //
        // Bathroom Facilities (row: 105)
        //
        //
        {
          key: 'Bk2e5',
          label: 'Is there a wheelchair accessible bathroom on the premises for employees?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/30.Bk2e5.png'],
          helpText: 'Is there a wheelchair accessible bathroom for employees? The minimum size of the accessible WC cubicle should be 180cm x 250cm.',
          popupLabel: 'Employee wheelchair accessible bathroom',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 3,
                onFalse: 0,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: 106,
        },
        {
          key: 'Bk2fD',
          label: 'How many wheelchair accessible bathrooms are on the premises?           \n',
          helpImage: null,
          helpText: 'What is the number wheelchair accessible bathrooms in the building? \n',
          popupLabel: 'Employee wheelchair accessible bathrooms',
          type: AppQuestion.TYPE_INTEGER,
          type2: {
            typeInteger: {
              minValue: 1,
              maxValue: Number.MAX_VALUE,
              scoring: [],
            }
          },
          maximumScore: 0,

          spreadsheetRow: 107,
          dependant: {
            questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises?
          },
        },
        {
          key: 'Bk2fN',
          label: 'Do some bathroom doors have braille, raised lettering or appropriate symbols to identify the bathroom type?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/24.Bk2fN.png'],
          helpText: 'Braille is a form of written language for blind people, in which characters are represented by patterns of raised dots that are felt with the fingertips. Raised (tactile) male/female/unisex/accessibility symbol for bathroom.  ',
          popupLabel: 'Braille/raised lettering/appropriate symbols on employee bathroom door',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 108,
        },
        {
          key: 'Bk2fX',
          label: 'Are there two doors in a series into any accessible bathroom where the wheelchair accessible toilet is located?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/11.Bk2fX.jpg'],
          helpText: 'Two doors in a series is sometimes referred to as \'lobby or vestibule\' and includes a space between an exterior opening and an interior opening.  ',
          popupLabel: 'Two doors into employee wheelchair accessible bathroom',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 0,
                onFalse: 0,
              }
            }
          },
          maximumScore: 0,

          spreadsheetRow: 109,
          dependant: {
            questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises?
          },
        },
        {
          key: 'Bk2fr',
          label: 'What is the distance between the two doors in the series? (eg. The passage between the two doors). (In cm)',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/12.Bk2fr.jpg'],
          helpText: 'Where there are two doors in a series into a bathroom, the lobby between the doors should enclose an area of a minimum of 157cm, free of any door swing. This space will allow a wheelchair user to pass through one door and let it close behind them before reaching to open the second door. ',
          popupLabel: 'Distance between two doors into employee wheelchair accessible bathroom',
          type: AppQuestion.TYPE_CM_MEASUREMENT,
          type2: {
            typeCmMeasurement: {
              minValue: 60,
              maxValue: 10 * 1000,
              scoring: [
                {
                  greaterThanOrEqualTo: 158,
                  score: 3
                },
              ]
            }
          },
          maximumScore: 3,

          spreadsheetRow: 110,
          dependant: {
            questionKey: 'Bk2fX',
          }
        },
        {
          key: 'Bk2f1',
          label: 'In the most wheelchair accessible bathroom, what type of door handles are on the bathroom doors?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/25.Bk2f1.png'],
          helpText: 'Can the door be opened with one hand and does not require tight grasping, pinching or twisting of the wrist? Lever handles - an automatic or push-button opener - round handles?\n',
          popupLabel: 'Employee accessible bathroom door handles',
          type: AppQuestion.TYPE_ENUM,
          type2: {
            typeEnum: {
              options: [
                FacilityEnumeratedConstants.TOILET_DOOR_TYPE.leverHandles,
                FacilityEnumeratedConstants.TOILET_DOOR_TYPE.automaticDoor,
                FacilityEnumeratedConstants.TOILET_DOOR_TYPE.pushButtonOpener,
                FacilityEnumeratedConstants.TOILET_DOOR_TYPE.roundHandles,
                FacilityEnumeratedConstants.TOILET_DOOR_TYPE.push,
              ],
              scoring: [
                {
                  codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.leverHandles.codeAsNumber,
                  score: 2,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.automaticDoor.codeAsNumber,
                  score: 3,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.pushButtonOpener.codeAsNumber,
                  score: 3,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.roundHandles.codeAsNumber,
                  score: 0,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.push.codeAsNumber,
                  score: 1,
                },
              ],
            }
          },
          maximumScore: 3,

          spreadsheetRow: 111,
          dependant: {
            questionKey: 'Bk2e5',
          }
        },
        {
          key: 'D1sjR',
          label: 'In the most wheelchair accessible bathroom, what type of door locks are on the bathroom door?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/135.D1sjR.svg'],
          helpText: 'Can the lock on the bathroom door be operated with one hand in a closed fist, ie does not require tight grasping, pinching or twisting of the wrist? ',
          popupLabel: 'Employee accessible bathroom door lock',
          type: AppQuestion.TYPE_ENUM,
          type2: {
            typeEnum: {
              options: [
                FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.leverHandle,
                FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.slideBolt,
                FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.thumbTurn,
                FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.other,
              ],
              scoring: [
                {
                  codeAsNumber: FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.leverHandle.codeAsNumber,
                  score: 1,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.slideBolt.codeAsNumber,
                  score: 0,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.thumbTurn.codeAsNumber,
                  score: 0,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.other.codeAsNumber,
                  score: 0,
                },
              ]
            }
          },
          maximumScore: 0,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'Bk2e5',
          }
        },
        {
          key: 'Bk2f_',
          label: 'In the most wheelchair accessible bathroom, what is the width of the door?   (In cm)',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/19.Bk2f_.jpg'],
          helpText: 'In centimetre’s, please measure the width of the bathroom door in a fully open position, from inside the door frame as shown.',
          popupLabel: 'Employee accessible bathroom door width',
          type: AppQuestion.TYPE_CM_MEASUREMENT,
          type2: {
            typeCmMeasurement: {
              minValue: 60,
              maxValue: 2 * 1000,
              scoring: [
                {
                  greaterThanOrEqualTo: 81,
                  lessThan: 91,
                  score: 2
                },
                {
                  greaterThanOrEqualTo: 91,
                  score: 3
                },
              ]
            }
          },
          maximumScore: 3,

          spreadsheetRow: 112,
          dependant: {
            questionKey: 'Bk2e5'
          }
        },
        {
          key: 'Bk2gJ',
          label: 'In the most wheelchair accessible bathroom, can you see yourself in the mirror from a seated position?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/32.Bk2gJ.png'],
          helpText: 'If the mirror is over a sink or counter top, is the mirror at a lower level to see your reflection from a seating position or for someone of short stature? A full length mirror will work too.',
          popupLabel: 'Employee accessible bathroom mirror at lower height',
          type: AppQuestion.TYPE_TERNARY,
          type2: {
            typeTernary: {
              scoring: {
                onNotApplicable: 1,
                onTrue: 1,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: 113,
          dependant: {
            questionKey: 'Bk2e5'
          }
        },
        {
          key: 'Bk2gT',
          label: 'In the most wheelchair accessible bathroom, what is the toilet height from the floor to the top of the toilet seat? (In cm)',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/33.Bk2gt.png'],
          helpText: 'In centimetres, please measure the toilet seat height from the floor to the top of the toilet seat.\n',
          popupLabel: 'Employee accessible bathroom toilet height',
          type: AppQuestion.TYPE_CM_MEASUREMENT,
          type2: {
            typeCmMeasurement: {
              minValue: 45,
              maxValue: 90,
              scoring: [
                {
                  greaterThanOrEqualTo: 46,
                  lessThan: 52,
                  score: 3
                },
              ]
            }
          },
          maximumScore: 3,

          spreadsheetRow: 114,
          dependant: {
            questionKey: 'Bk2e5'
          },
        },
        {
          key: 'Bk2gn',
          label: 'In the most wheelchair accessible bathroom, what is the widest clear floor space beside the accessible toilet? ',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/77.Bk2gn.svg'],
          helpText: 'In centimetres, please measure the largest (widest) space at one side of the toilet so a wheelchair can fit beside the toilet for lateral transfer.',
          popupLabel: 'Clear floor space beside employee accessible toilet',
          type: AppQuestion.TYPE_CM_MEASUREMENT,
          type2: {
            typeCmMeasurement: {
              minValue: 60,
              maxValue: 10 * 1000,
              scoring: [
                {
                  greaterThanOrEqualTo: 90,
                  lessThan: 91,
                  score: 2
                },
                {
                  greaterThanOrEqualTo: 91,
                  score: 3
                },
              ]
            }
          },
          maximumScore: 3,

          spreadsheetRow: 115,
          dependant: {
            questionKey: 'Bk2e5'
          },
        },
        {
          key: 'Bk2gx',
          label: 'In the most wheelchair accessible bathroom, is there a grab bar beside the accessible toilet?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/35.Bk2gx.png'],
          helpText: 'Are there grab rails beside the toilet positioned to assist balance?',
          popupLabel: 'Grab bar beside employee accessible toilet',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 116,
          dependant: {
            questionKey: 'Bk2e5'
          },
        },
        {
          key: 'Bk2g7',
          label: 'In the most wheelchair accesible bathroom, is there a clear space under the sink to enable a wheelchair user to fit under?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/36.Bk2g7.png'],
          helpText: 'Is there clear knee space underneath the wash basin for a wheelchair user to fit? Recommended 70cm knee clearance space underneath the wash basin.',
          popupLabel: 'Clear space under the sink in employee accessible toilet',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 117,
          dependant: {
            questionKey: 'Bk2e5'
          }
        },
        {
          key: 'Bk2hF',
          label: 'In the most wheelchair accessible bathroom, what type of handle is on the sink? ',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/27.Bk2hF.png'],
          helpText: 'Does the sink have an automatic tap / faucet or have longer levers handles for people with poor hand dexterity to turn on and off? \n',
          popupLabel: 'Sink handle type',
          type: AppQuestion.TYPE_ENUM,
          type2: {
            typeEnum: {
              options: [
                FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.lever,
                FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.round,
                FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.press,
                FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.sensor,
              ],
              scoring: [
                {
                  codeAsNumber: FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.lever.codeAsNumber,
                  score: 2,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.round.codeAsNumber,
                  score: 0,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.press.codeAsNumber,
                  score: 2,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.sensor.codeAsNumber,
                  score: 2,
                },
              ],
            }
          },
          maximumScore: 2,

          spreadsheetRow: 118,
          dependant: {
            questionKey: 'Bk2e5'
          },
        },
        {
          key: 'Bk2hP',
          label: 'In the most wheelchair accessible bathroom, is there an alarm cord or button for emergency use?  ',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/38.Bk2hP.svg'],
          helpText: 'Is there a red pull cord or alarm button to trigger an assistance alarm in the accessible bathroom?',
          popupLabel: 'Emergency alarm cord',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 119,
          dependant: {
            questionKey: 'Bk2e5'
          },
        },
        {
          key: 'Bk2hZ',
          label: 'Is there a roll in shower on the premises?\n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/78.Bk2hZ.svg'],
          helpText: 'Does the accessible bathroom have a level floor roll-in shower wet room?\n',
          popupLabel: 'Roll-in shower',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 3,
                onFalse: 0,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: 120,
          dependant: {
            questionKey: 'Bk2e5'
          },
        },
        {
          key: 'Bk2hj',
          label: 'Please take 2 photos of the most  accessible wheelchair bathroom from different angles (in landscape mode)',
          helpImage: null,
          helpText: '',
          popupLabel: '',
          type: AppQuestion.TYPE_PHOTO,
          maximumScore: 0,

          spreadsheetRow: 121,
          dependant: {
            questionKey: 'Bk2e5'
          },
        },
        {
          key: 'QZ_0J',
          label: 'Do you have any other comments or information about this area?',
          helpImage: null,
          helpText: '',
          popupLabel: '',
          type: AppQuestion.TYPE_TEXT,
          maximumScore: 0,

          spreadsheetRow: -1,
        },


        {
          key: 'Cjlgx',
          label: 'What type of handles are on the general bathroom entrance doors? ',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/25.Cjlgx.png'],
          helpText: 'Can the door be opened with one hand in a closed fist, i.e does not require tight grasping, pinching or twisting of the wrist? These could be lever handles - an automatic or push-button opener - round handles?',
          popupLabel: 'Visitor bathroom door handle type',
          type: AppQuestion.TYPE_ENUM,
          type2: {
            typeEnum: {
              options: [
                FacilityEnumeratedConstants.TOILET_DOOR_TYPE.automaticDoor,
                FacilityEnumeratedConstants.TOILET_DOOR_TYPE.pushButtonOpener,
                FacilityEnumeratedConstants.TOILET_DOOR_TYPE.leverHandles,
                FacilityEnumeratedConstants.TOILET_DOOR_TYPE.roundHandles,
                FacilityEnumeratedConstants.TOILET_DOOR_TYPE.push,
              ],
              scoring: [
                {
                  codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.automaticDoor.codeAsNumber,
                  score: 3,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.pushButtonOpener.codeAsNumber,
                  score: 3,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.leverHandles.codeAsNumber,
                  score: 2,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.roundHandles.codeAsNumber,
                  score: 0,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.TOILET_DOOR_TYPE.push.codeAsNumber,
                  score: 1,
                },
              ]
            }
          },
          maximumScore: 3,

          spreadsheetRow: -1,
        },
        {
          key: 'D1sTp',
          label: 'What type of door locks are on the general bathroom doors?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/135.D1sTp.svg'],
          helpText: 'Can the lock on the bathroom door be operated with one hand in a closed fist, ie does not require tight grasping, pinching or twisting of the wrist? ',
          popupLabel: 'Visitors bathroom type of lock',
          type: AppQuestion.TYPE_ENUM,
          type2: {
            typeEnum: {
              options: [
                FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.leverHandle,
                FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.slideBolt,
                FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.thumbTurn,
                FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.other,
              ],
              scoring: [
                {
                  codeAsNumber: FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.leverHandle.codeAsNumber,
                  score: 1,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.slideBolt.codeAsNumber,
                  score: 0,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.thumbTurn.codeAsNumber,
                  score: 0,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.BATHROOM_DOOR_LOCK_TYPE.other.codeAsNumber,
                  score: 0,
                },
              ]
            }
          },
          maximumScore: 1,

          spreadsheetRow: -1,
        },

        {
          key: 'Cjp6z',
          label: 'What is the width of the general bathroom doors? (In cm)',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/26.Cjp6z.jpg'],
          helpText: '',
          popupLabel: 'Visitor bathroom door width measurement',
          type: AppQuestion.TYPE_CM_MEASUREMENT,
          type2: {
            typeCmMeasurement: {
              minValue: 45,
              maxValue: 2000,
              scoring: [
                {
                  greaterThanOrEqualTo: 81,
                  lessThan: 91,
                  score: 2
                },
                {
                  greaterThanOrEqualTo: 91,
                  score: 3
                },
              ]
            }
          },
          maximumScore: 3,

          spreadsheetRow: -1,
        },
        {
          key: 'Cjp_z',
          label: 'Can you see yourself in the mirror from a seated position in the general bathrooms?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/26.Cjp_z.png'],
          helpText: '',
          popupLabel: 'Visitor bathroom mirror at a lower height ',
          type: AppQuestion.TYPE_TERNARY,
          type2: {
            typeTernary: {
              scoring: {
                onNotApplicable: 1,
                onTrue: 1,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: -1,
        },
        {
          key: 'Cjwul',
          label: 'What type of tap handles are on the sink in the general bathrooms?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/27.Cjwul.png'],
          helpText: 'Does the sink have tap handles with longer levers for people with poor hand dexterity to turn on and off? Or round head taps or is the water supply from automatic sensor operated controls?',
          popupLabel: 'Visitor bathroom sink handle type',
          type: AppQuestion.TYPE_ENUM,
          type2: {
            typeEnum: {
              options: [
                FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.lever,
                FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.round,
                FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.press,
                FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.sensor,
              ],
              scoring: [
                {
                  codeAsNumber: FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.lever.codeAsNumber,
                  score: 2,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.round.codeAsNumber,
                  score: 0,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.press.codeAsNumber,
                  score: 2,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.TOILET_SINK_HANDLE_TYPE.sensor.codeAsNumber,
                  score: 2,
                },
              ]
            }
          },
          maximumScore: 2,

          spreadsheetRow: -1,
        },
        {
          key: 'CjqNt',
          label: 'Is there a sink at a lower height in the general bathrooms?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/28.CjqNt.png'],
          helpText: 'Is there a sink/washbasin positioned at a lower height in the visitors bathroom for children, wheelchair users or people of shorter stature?',
          popupLabel: 'Visitor bathroom sink at lower height',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 1,
                onFalse: 0,
              }
            }
          },
          maximumScore: 1,

          spreadsheetRow: -1,
        },
        {
          key: 'CjqVr',
          label: 'What is the height of the lower sink in the general bathrooms? ',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/36.CjqVr.png'],
          helpText: 'In centimetre’s, please measure the height of the lower sink from the floor to the top of the washbasin.',
          popupLabel: 'Visitor bathroom height of lower sink',
          type: AppQuestion.TYPE_CM_MEASUREMENT,
          type2: {
            typeCmMeasurement: {
              minValue: 45,
              maxValue: 2000,
              scoring: [
                {
                  greaterThanOrEqualTo: 75,
                  lessThan: 86,
                  score: 1
                },
                {
                  greaterThanOrEqualTo: 86,
                  score: 0
                },
              ]
            }
          },
          maximumScore: 1,

          spreadsheetRow: -1,
          dependant: {
            questionKey: 'CjqNt'
          },
        },


      ],
      sections: {
        evaluation: [
          // #1
          {
            title: `General Bathrooms`,
            questionKeys: [
              'Bkvf3',
              'Cjlgx',
              'D1sTp',
              'Cjp6z',
            ],
            subSections: [],
          },
          // #2
          {
            title: `General Bathrooms`,
            questionKeys: [
              'Cjp_z',
              'Cjwul',
              'CjqNt',
              'CjqVr'
            ],
            subSections: [],
          },
          // #3
          {
            title: 'Accessible Bathrooms',
            questionKeys: [
              'Bk2e5', // Is there a wheelchair accessible bathroom on the premises?
              'Bk2fD', // How many wheelchair accessible bathrooms are on the premises?
              'Bk2fN', // Do some bathroom doors have Braille and/or lettering to identify the bathroom type?
              'Bk2fX', // Are there two doors in a series into the main bathroom area where the wheelchair toilet is located?
              'Bk2fr', // What is the distance between the two doors in the series? (eg. the passage between the two doors).                                                        (In cm)
              'Bk2hZ', // Is there a roll in shower on the premises?
            ],
            subSections: [],
          },
          // #4
          {
            title: 'Accessible Bathrooms',
            questionKeys: [
              'Bk2f1', // What type of door handles are on the accessible bathroom doors?
              'D1sjR', // In the most wheelchair accessible bathroom, what type of door locks are on the bathroom door?
              'Bk2f_', // What is the width of the door of the most accessible bathroom?   (In cm)
              'Bk2gJ', // Is the bathroom mirror set at a lower height?
              'Bk2gT', // What is the accessible toilet height from the floor to the top of the toilet seat? (In cm)
              'Bk2gn', // What is the widest clear floor space beside the accessible toilet?
            ],
            dependantDescriptor: {
              questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises?
            },
            subSections: [],
          },
          // #5
          {
            title: 'Accessible Bathrooms',
            questionKeys: [
              'Bk2gx', // Is there a grab bar beside the accessible toilet?
              'Bk2g7', // Is there a clear space under the sink to enable a wheelchair user to fit under?
              'Bk2hF', // What type of handle is on the sink?
              'Bk2hP', // Is there an alarm cord or button in the most accessible bathroom for emergency use?
            ],
            dependantDescriptor: {
              questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises?
            },
            subSections: [],
          },
          // #6
          {
            title: 'Accessible Bathrooms',
            questionKeys: [
              'Bk2hj', // Please take 2 photos of the wheelchair bathroom (in landscape mode)
            ],
            dependantDescriptor: {
              questionKey: 'Bk2e5', // Is there a wheelchair accessible bathroom on the premises?
            },
            subSections: [],
          },
          // #7
          {
            title: 'Any other comments or feedback',
            questionKeys: [
              'QZ_0J',
            ],
            subSections: [],
          }

        ],
        portal: [
        ],
      },
      portal: {
        navigationMenu: {
          iconImgBase: 'assets/service.navigation-menu/fontawesome.building-regular'
        }
      },
      spreadsheetRow: 105,
      uuid: 'c5cc9311-ca63-4dde-8cda-82e091e7f203'
    }),
    new AppAspectDefinition({
      name: 'Safety Procedures',
      // firebaseAnswersRoot: '/answers-x/facilities',
      firebaseAspectId: 'safety-procedures',
      questions: [

        //
        //
        // SAFETY AND EVACUATION (row: 122)
        //
        //

        {
          key: 'Bk3dP',
          label: 'Does the fire alarm system have visible flashing lights?',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/80.Bk3dP.svg'],
          helpText: 'Alarms with visual flashing lights assist people with a hearing impairments.',
          popupLabel: 'Visible flashing alarm ',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 2,
                onFalse: 0,
              }
            }
          },
          maximumScore: 2,

          spreadsheetRow: 123,
        },
        {
          key: 'Bk3dZ',
          label: 'Is there an emergency evacuation procedure available for people with accessibility needs?',
          helpImage: null,
          helpText: 'Do you have an emergency evacuation procedure specifically for those with accessibility needs?',
          popupLabel: 'Emergency evacuation procedure',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 3,
                onFalse: 0,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: 124,
        },
        {
          key: 'Bk3dj',
          label: 'Is emergency evacuation information available in both visual and audible formats?',
          helpImage: null,
          helpText: 'An emergency evacuation plan shows the immediate escape routes in the case of danger. It should be available in all formats including visual and auditory formats - written, braille, digital audio, large print.',
          popupLabel: 'Visual and audible evacuation information',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 3,
                onFalse: 0,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: 125,
        },
        {
          key: 'Bk3dt',
          label: 'Is there an emergency evacuation chair in the building?\n',
          helpImage: null,
          helpImages: ['facilities-help-images/Consolidated_Facilities_Images/81.Bk3dt.svg'],
          helpText: 'Evacuation chairs provide mobility impaired people with a safe and smooth descent of stairways to evacuate a building in the event of an emergency, such as a fire.',
          popupLabel: 'Emergency evacuation chair',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 3,
                onFalse: 0,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: 126,
        },
        {
          key: 'Bk3d3',
          label: 'Do staff receive disability awareness training?\n',
          helpImage: null,
          helpText: 'Have your staff received any disability awareness training to help them better understand the key issues regarding disabilities?',
          popupLabel: 'Staff disability awareness training',
          type: AppQuestion.TYPE_BOOLEAN,
          type2: {
            typeBoolean: {
              scoring: {
                onTrue: 3,
                onFalse: 0,
              }
            }
          },
          maximumScore: 3,

          spreadsheetRow: 127,
        },
        {
          key: 'Bk3eB',
          label: 'Does the building have an accessibility certification, inclusion or universal design standards accreditation?',
          helpImage: null,
          helpText: 'Building accreditation establishes physical building standards in accessibility. There are many forms of accessibility accreditation globally. Does your building have any accessibility standards accreditation?',
          popupLabel: 'Accessibility Certification',
          type: AppQuestion.TYPE_ENUM,
          type2: {
            typeEnum: {
              options: [
                FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.ada,
                FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.dac,
                FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.partM,
                FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.bac,
                FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.nbc,
                FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.bbr,
                FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.other,
                FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.none,
              ],
              scoring: [
                {
                  codeAsNumber: FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.ada.codeAsNumber,
                  score: 3,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.dac.codeAsNumber,
                  score: 3,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.partM.codeAsNumber,
                  score: 3,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.bac.codeAsNumber,
                  score: 3,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.nbc.codeAsNumber,
                  score: 3,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.bbr.codeAsNumber,
                  score: 3,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.other.codeAsNumber,
                  score: 3,
                },
                {
                  codeAsNumber: FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.none.codeAsNumber,
                  score: 0,
                },
              ],
            }
          },
          maximumScore: 3,

          spreadsheetRow: 128,
          enumOptions: [
            FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.ada,
            FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.dac,
            FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.partM,
            FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.bac,
            FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.nbc,
            FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.bbr,
            FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.other,
            FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.none,
          ]
        },
        {
          key: 'DHYrn',
          label: 'What type of accessibility certification, inclusion or universal design standards accreditation does the building have ?',
          helpImage: null,
          helpText: 'Please provide more details on the type of certification the building has in relation to accessibility.',
          popupLabel: 'Building accessibility cert',
          type: AppQuestion.TYPE_TEXT,
          maximumScore: 2,

          spreadsheetRow: 158,
          dependant: {
            questionKey: 'Bk3eB',
            truthyEnumCodes: [
              FacilityEnumeratedConstants.ACCESSIBILITY_CERTIFICATION.other.codeAsNumber
            ]
          }
        },
        {
          key: 'QZ_1P',
          label: 'Do you have any other comments or information about this area?',
          helpImage: null,
          helpText: '',
          popupLabel: '',
          type: AppQuestion.TYPE_TEXT,
          maximumScore: 0,

          spreadsheetRow: -1,
        },

      ],
      sections: {
        evaluation: [
          {
            title: 'Safety Procedures',
            questionKeys: [
              'Bk3dP', // Does the fire alarm system have visual flashing lights?
              'Bk3dj', // Is evacuation information available in both visual and audible formats?
              'Bk3dZ', // Is there an emergency evacuation procedure available for people with accessible needs?
            ],
            subSections: [],
          },
          {
            title: 'Safety Procedures',
            questionKeys: [
              'Bk3dt', // Is there an emergency evacuation chair in the building?
              'Bk3d3', // Do staff receive disability awareness training?
              'Bk3eB', // Does the building have an accessibility certification, inclusion or universal design standards accreditation?
              'DHYrn', // What type of accessibility certification, inclusion or universal design standards accreditation does the building have ?
            ],
            subSections: [],
          },
          // #3
          {
            title: 'Any other comments or feedback',
            questionKeys: [
              'QZ_1P',
            ],
            subSections: [],
          }

        ],
        portal: [
        ],
      },
      portal: {
        navigationMenu: {
          iconImgBase: 'assets/service.navigation-menu/fontawesome.building-regular'
        }
      },
      spreadsheetRow: 105,
      uuid: '1978f99b-a82f-41f8-9af9-fa8cb0dc6cd0'
    }),
  ];




}
