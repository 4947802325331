


export class BrowserAppEvaluationToolInfo {

  public version = '2024.9.16.1';

  constructor() {
  }

}
