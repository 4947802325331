import {IProduct} from "../../model.product/IProduct";
import {DiageoQuestions} from "./DiageoQuestions";
import { ReportSection} from "../../model.product/ReportSection";
import {FacilityEnumeratedConstants} from "./FacilityEnumeratedConstants";
import {FacilitySummaryStructure} from "./FacilitySummaryStructure";
import {FacilityReportSections} from "./FacilityReportSections";
import {AppAspectDefinition} from "../../javascript.lib.mojo-base/model/app.aspect/AppAspectDefinition";
import {ScoreRuleSet} from "../../javascript.lib.mojo-base/model/score/ScoreRuleSet";

export class DiageoFacilityProduct implements IProduct {


  private static readonly BAR_LOUNGE_AREA = new ReportSection( {
    title: 'Bar/Lounge Area',
    questionKeys: [
      'QZ_lT',
      'QZ_ob',
      'QZ_pD',
      'QZ_p_',
      'QZ_qx',
      'QZ_rZ',
      'QZ_sf',
    ],
    identifier: 'bar-lounge-area',
    features: [
    ],
    overview: 'Bar/Lounge Area',
  });

  evaluationSections = DiageoQuestions.aspects;

  questions = AppAspectDefinition.ARRAY.toQuestionSet( DiageoQuestions.aspects );

  reportSections = DiageoFacilityProduct.buildReportSections();

  scoreRules: ScoreRuleSet = new ScoreRuleSet([]);
  summarySections = FacilitySummaryStructure.summarySections;

  version = "1.0.0";



  getAllEnums() {
    return FacilityEnumeratedConstants.getAllEnums();
  }


  printCodes() {

  }


  private static buildReportSections(): ReportSection[] {

    const answer: ReportSection[] = [];

    for( const reportSection of FacilityReportSections.VALUE ) {

      // const json = JSON.stringify( reportSection.value );
      // const value2: IReportSection = JSON.parse( json );

      answer.push( reportSection );

      // add the bar after the breakout area
      if( 'Breakout Areas' === reportSection.value.title ) {

        answer.push( DiageoFacilityProduct.BAR_LOUNGE_AREA );
      }

      // const reportSection2 = new ReportSection( value2 );
      // answer.push( reportSection2 );

    }

    return answer;
  }

}
