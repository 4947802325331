import {ReportSection} from "../../model.product/ReportSection";


export class FacilityReportSections {

  public static readonly VALUE: ReportSection[] = [
    new ReportSection({
      title: 'Parking',
      overview: 'Parking',
      questionKeys: [
        'Bkp11',
        'MpjwN',
        'Mpj2n',
        'BkqAn',
        'BkqAx',
        'BkqA7',
        'BkqBF',
        'BkqBP',
        'BkqBZ',
        'BkqBj'
      ],
      identifier: 'parking',
      features: [
        {
          identifier: 'designated-accessible-parking-spaces',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkqAn'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'accessible-entrance-from-indoor-car-park',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkqA7'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'pay-stations',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Mpj2n'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'door-security-and-entry-systems',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'doors-in-series',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkqBZ'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      title: 'Building Entrance',
      overview: 'Building Entrance',
      questionKeys: [
        'BkqB3',
        'BkqCB',
        'BkqCL',
        'BkqCf',
        'BkqCp',
        'BkqCz',
        'BkqC9',
        'BkqCV',
        'CjAUx',
        'CjEZ3',
        'CjFQt',
        'BkqBt',
        'Cjk03',
      ],
      identifier: 'building-entrance',
      features: [
        {
          identifier: 'building-entrances',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},

        },
        {
          identifier: 'accessible-routes-and-crossing-points',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},

        },
        {
          identifier: 'step-free-access-at-main-entrance',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkqB3'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},

        },
        {
          identifier: 'number-of-steps-up-to-the-main-building-entrance',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkqCB'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},

        },
        {
          identifier: 'automatic-or-manual-door-at-entrance',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkqCL'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},

        },
        {
          identifier: 'glass-entrance-doors',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkqCz'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},

        },
        {
          identifier: 'alternative-accessible-entrance',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkqCV'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},

        },
        {
          identifier: 'automatic-or-manual-door-at-alternative-accessible-entrance',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['CjEZ3'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},

        },
        {
          identifier: 'door-width-of-alternative-accessible-entrance',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['CjFQt'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},

        },
        {
          identifier: 'accessible-entrance-clearly-signposted',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkqBt'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},

        },
      ],
    }),
    new ReportSection({
      title: 'Reception area',
      overview: 'Reception area',
      questionKeys: [
        'Bkve7'
        , 'BkvfF'
        , 'C9KCH'
        , 'BkvfP'
        , 'Mpj2x'
        , 'Bkvfj'
        , 'BkvfZ'
      ],
      identifier: 'reception-area',
      features: [
        {
          identifier: 'lower-counter-or-desk-at-reception-area',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bkve7'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},

        },
        {
          identifier: 'assistive-listening-device',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkvfF'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},

        },
        {
          identifier: 'seating-area',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['C9KCH'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'tactile-maps-and-floor-plans',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Mpj2x'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'directional-and-informational',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkvfP'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'guide-dogs',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bkvfj'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      title: 'Facilities',
      overview: 'Facilities',
      questionKeys: [
        'BkyVv'
        , 'BkyV5'
        , 'BkyWD'
        , 'BkyWN'
        , 'BkyWX'
        , 'BkyWh'
        , 'BkyWr'
        , 'BkyW1'
      ],
      identifier: 'facilities',
      features: [
        {
          identifier: 'baby-changing-facilities',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkyVv'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'breastfeeding-room',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkyV5'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'gym-or-fitness-facilities',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkyW1'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'wheelchair-accessible-cloakroom',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkyWN'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'storage-lockers-at-varying-heights',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkyWr'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'designated-quiet-spaces',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Mpj4z'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      title: 'Hallways & Corridors - Internal route of travel',
      overview: 'Hallways & Corridors - Internal route of travel',
      questionKeys: [
        'Bky5D'
      ],
      identifier: 'hallways-and-corridors',
      features: [
        {
          identifier: 'accessible-route',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bky5D'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'good-lighting-throughout-the-building',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bky5N'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      title: 'Lighting',
      overview: 'Lighting',
      questionKeys: [
        'Bky5N'
      ],
      identifier: 'lighting',
      features: [
        {
          identifier: 'lighting-to-facilitate',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'lighting-levels-at-workstations',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      title: 'Cafeteriea & Food Services',
      overview: 'Cafeteriea & Food Services',
      questionKeys: [
        'BkzJT'
        , 'BkzJd'
        , 'Mpj27'
        , 'Mpj3F'
        , 'Mpj3Z'
        , 'BkzJn'
        , 'Mpj3t'
        , 'BkzJx'
        , 'Mpj33'
        , 'Mpj4B'
        , 'BkzJ7'
        , 'Mpj4L'
        , 'Mpj4V'
      ],
      identifier: 'cafeteria-and-food-services',
      features: [
        {
          identifier: 'level-or-ramped-access-to-cafeteria-area',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkzJd'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'movable-tables-and-chairs',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkzJn'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'self-service-and-food-counters',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Mpj3F'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'display-cabinets',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Mpj33'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'table-height',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkzJx'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'large-print-menus',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Mpj3Z'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'vending-machines',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Mpj4V'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      title: 'Breakout Areas',
      overview: 'Breakout Areas',
      questionKeys: [
        'CmgRH'
        , 'Cmgc1'
        , 'CmgpB'
        , 'CmgyZ'
        , 'Cmg8t'
        , 'CmhaZ'
        , 'Cmie_'
      ],
      identifier: 'breakout-spaces',
      features: [
        {
          identifier: 'breakout-areas-that-include-a-kitchenette-or-refreshment-facility',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['CmgRH'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'level-or-ramped-access-to-breakout-area',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Cmgc1'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'appliances-at-suitable-height-in-breakout-area',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['CmgpB'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'kitchen-sink-at-suitable-height-in-breakout-area',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['CmgyZ'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'cupboards-or-cabinets-at-reachable-height',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Cmg8t'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'movable-furniture',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['CmhaZ'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'visually-contrasting-appliances',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Cmie_'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      title: 'Event Spaces',
      overview: 'Event Spaces',
      questionKeys: [
        'Bkzpp'
        , 'Bkzpz'
        , 'Mpj4f'
        , 'Bkzp9'
        , 'BkzqH'
        , 'Mpj4p'
        , 'Bkzqb'
      ],
      identifier: 'event-spaces',
      features: [
        {
          identifier: 'wheelchair-accessible-seating-spaces',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bkzpz'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'sight-lines',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Mpj4f'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'assistive-listening-systems',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bkzp9'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'presentation-screens-text-display-or-subtitles',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkzqH'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'service-animals',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Mpj4p'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      title: 'Stairs and Lifts/Elevators',
      overview: 'Stairs and Lifts/Elevators',
      questionKeys: [
        , 'BkzKF'
        , 'BkzKP'
        , 'BkzeF'
        , 'Bkz__'
        , 'Bk0AJ'
        , 'Bk0AT'
        , 'Bk0An'
        , 'Bk0Ax'
        , 'Bk0A7'
        , 'Bk0BF'
        , 'CmlfV'
        , 'Bk0BP'
      ],
      identifier: 'stairs-and-lifts',
      features: [
        {
          identifier: 'internal-stairs',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkzKF'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'illumination-on-internal-stairwells',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'handrails-on-stairs',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'detectable-warning-indicators-on-stairs',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['BkzeF'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'elevator-call-buttons',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['CmlfV'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'accessible-route-to-elevator',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk0AJ'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'lift-elevator-measurements',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk0AT', 'Bk0An', 'Bk0Ax'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'voice-announcements-in-the-elevator',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk0A7'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'raised-numbers-or-braille-and-lights-on-lift-buttons',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk0BF'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'emergency-alarm-in-elevator',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      title: 'Office Workstations',
      overview: 'Office Workstations',
      questionKeys: [
        'Bk2BD'
        , 'Bk2BN'
        , 'Bk2Br'
        , 'Bk2BX'
        , 'Bk2Bh'
        , 'Bk2B1'
        , 'Bk2B_'
        , 'Mpj4z'
      ],
      identifier: 'office-workstations',
      features: [
        {
          identifier: 'floor-surfaces',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk2BD'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'furniture',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk2BN'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'desk-height',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk2Br'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'equipment-controls-and-switches',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk2BX'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'switches-and-sockets-visual-contrast',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk2Bh'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'print-stations-and-photocopying-machines',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk2B_'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      title: 'Meeting rooms ',
      overview: 'Meeting rooms ',
      questionKeys: [
        'Bk0fj'
        , 'Bk0ft'
        , 'Bk0f3'
        , 'Bk0gB'
        , 'Bk0gL'
        , 'Bk0gV'
        , 'Bk0gf'
        , 'Cmlsx'
        , 'Bk0gp'
      ],
      identifier: 'meeting-rooms',
      features: [
        {
          identifier: 'meeting-room-signage',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk0f3'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'door-width',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk0gB'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'door-opening-force',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk0gL'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'movable-furniture',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk0gV'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'assistive-listening-systems',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk0gf'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'captioning-and-subtitles',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Cmlsx'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      title: 'Training Rooms',
      overview: 'Training Rooms',
      questionKeys: [
        'Bk1dH'
        , 'Bk1dR'
        , 'Bk1db'
        , 'Bk1dl'
        , 'Bk1dv'
        , 'Bk1d5'
        , 'Bk1eD'
        , 'Bk1eN'
        , 'Bk1eX'
      ],
      identifier: 'training-rooms',
      features: [
        {
          identifier: 'training-room-signage',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk1db'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'door-width',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk1dl'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'door-opening-force',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk1dv'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'movable-furniture',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk1d5'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'assistive-listening-systems',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk1eD'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'captioning-and-subtitles',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk1eN'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      title: 'Bathroom Facilities',
      overview: 'Bathroom Facilities',
      questionKeys: [
        'Bkvf3'
        , 'Cjlgx'
        , 'D1sTp'
        , 'Cjp6z'
        , 'Cjp_z'
        , 'Cjwul'
        , 'CjqNt'
        , 'CjqVr'
        , 'Bk2e5'
        , 'Bk2fD'
        , 'Bk2fN'
        , 'Bk2fX'
        , 'Bk2fr'
        , 'Bk2f1'
        , 'D1sjR'
        , 'Bk2f_'
        , 'Bk2gJ'
        , 'Bk2gT'
        , 'Bk2gn'
        , 'Bk2gx'
        , 'Bk2g7'
        , 'Bk2hF'
        , 'Bk2hP'
        , 'Bk2hZ'
        , 'Bk2hj'
      ],
      identifier: 'bathrooms',
      features: [
        {
          identifier: 'signage-on-general-bathroom-or-restroom-door',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bkvf3'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'type-of-handles-on-general-bathroom-or-restroom-door',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Cjlgx'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'general-bathroom-or-restroom-door-width',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Cjp6z'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'mirror-height-in-general-bathroom-or-restroom',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Cjp_z'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'type-of-handles-on-sink-or-washbasins-in-general-bathroom-or-restroom',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Cjwul'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'sink-or-washbasin-positioned-at-a-lower-height-in-genera-bathroom-or-restroom',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['CjqVr'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'wheelchair-accessible-bathroom-or-restroom',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk2e5'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'signage-on-wheelchair-accessible-bathroom-or-restroom',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk2fN'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'type-of-handles-on-wheelchair-accessible-bathroom-or-restroom-door',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['D1sTp'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'wheelchair-accessible-bathroom-or-restroom-door-width',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk2f_'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'two-doors-in-series-in-wheelchair-accessible-bathroom-or-restroom',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk2fr'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'door-locks-in-wheelchair-accessible-bathroom-or-restroom',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['D1sjR'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'toilet-seat-height-in-wheelchair-accessible-bathroom-or-restroom',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk2gT'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'grab-bar-beside-the-wheelchair-accessible-toilet',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk2gx'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'clear-space-beneath-the-sink-or-washbasin-in-wheelchair-accessible-bathroom-or-restroom',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk2g7'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'widest-clear-floor-space-beside-toilet-in-wheelchair-accessible-bathroom-or-restroom',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk2gn'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'mirror-at-lower-height-in-wheelchair-accessible-bathroom-or-restroom',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk2gJ'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'type-of-handles-on-sink-or-washbasin-in-wheelchair-accessible-bathroom-or-restroom',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk2hF'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'emergency-assistance-alarm-bell-or-cord-in-wheelchair-accessible-bathroom-or-restroom',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk2hP'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'wet-room-or-roll-in-shower',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk2hZ'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      title: 'Safety Procedures',
      overview: 'Safety Procedures',
      questionKeys: [
        'Bk3dP'
        , 'Bk3dZ'
        , 'Bk3dj'
        , 'Bk3dt'
      ],
      identifier: 'safety-and-evacuation',
      features: [
        {
          identifier: 'personal-emergency-evacuation-plan-peeps',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'fire-alarm-with-a-visual-indicator',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk3dP'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'emergency-evacuation-procedure',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk3dZ'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'evacuation-information-in-visual-and-audible-formats',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk3dj'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'emergency-evacuation-chair',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk3dt'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      title: 'Disability Awareness Training',
      overview: 'Disability Awareness Training',
      questionKeys: [
        'Bk3d3',
      ],
      identifier: 'disability-awareness-training',
      features: [
        {
          identifier: 'disability-awareness-training',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: ['Bk3d3'],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'training-resources',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
      ],
    }),
    new ReportSection({
      title: 'Accessibility Accreditation',
      overview: 'Accessibility Accreditation',
      questionKeys: [
        'Bk3eB',
        'DHYrn',
      ],
      identifier: 'accessibility-certification',
      features: [
        {
          identifier: 'accessibility-of-buildings-1',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
        {
          identifier: 'accessibility-of-buildings-2',
          inclusionCriteria: {
            ifLessThanFullScore: {
              questionKeys: [],
            }
          },
          capexLight: [],
          capexIntensive: [],
          complianceScoring: {},
        },
      ],
    }),
  ];


}

